.sidebar-main {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $secondary;
    box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    z-index: 9999;
    height: 100%;
    .accordion-button:not(.collapsed), .accordion-button, .accordion-button:not(.collapsed) {
        background-color: transparent;
        border: none;
        //line-height: 2.34em;
        background-image: none;
        border-radius: $border-radius-4;
        color: $white;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 7px 10px 7px;
        cursor: pointer;
        color: $white;
        font-size: $text-14;
        font-weight: $font-regular;
    }
    .accordion-button:not(.collapsed):not(.statick-link){
        color: $primary;
    }
    .accordion-item {
        background-color: $white-10;
        border: none;
        padding: 0;
        margin-bottom: 5px;
        border-radius: $border-radius-4;
        min-height: 45px;
    }
    .accordion-button {
        &::after {
            content: "\e92f";
            font-family: $icons-font-family;
            font-size: size(17);
            background-image: none;
            width: auto;
            height: auto;
            line-height: normal;
            margin-top: 2px;
            display: inline-block;
        }
        &:hover, &.active-link {
            color: $primary;
        }
    }
    .accordion-button:not(.collapsed) {
        &::after {
            transform: rotate(90deg);
        }
    }
    .sub-nav {
        padding-left: size(14);
        border-bottom: none;
        border-top: 1px solid $white-10;
        padding-top: size(14);
        padding-bottom: size(14);
        border-radius: 0 0 $border-radius-4 $border-radius-4;
        li {
            white-space: nowrap;
        }
        li.active {
            .k-link {
            color: $primary;
            }
        }
    }
  
    .k-link {
        background-color: transparent;
        border: none;
        background-image: none;
        border-radius: $border-radius-4;
        color: $white-5;
        box-shadow: none;
        padding: size(10) size(10) size(10);
        position: relative;
        font-weight: 500;
        display: block;
        // &::before {position: absolute; left: 0; top: 5px;content: "\e92e"; font-family: $icons-font-family; transform: rotate(90deg);}
        &:hover {
            color: $primary;
        }
        &:focus {
            color: $primary;
        }
        &.active-link{
            color: $primary;
            &-on-route{
                color: $primary;    
            }
        }
        
    }
    .statick-link {
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .sidebar-icon {
        font-size: $text-18;
        position: absolute;
        left: 15px;
        top: 5px;
    }
    .accordion-body {
        background-color: transparent;
        padding: 0;
        border-radius: 0 0 4px 4px;
    }
  
    .toggle-arrow {
        font-size: size(17);
    }
    .k-panelbar {
        .k-panel {
            background-color: transparent;
        }
    }
    .sidebar-content {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        height: -moz-calc(100% - (75px + 27px));
        height: -webkit-calc(100% - (75px + 27px));
        height: calc(100% - (75px + 27px));
        @extend %scroll;
        padding: 0 10px;
        @include bp(md-screen) {
            height: 0px;
            @include transition(all 0.0s ease-in-out);
        }
    }
    .logo-text {
       fill: $white;
    }
    .sidebar-header {
        padding: 15px 20px 15px;
        position: relative;
        //margin-bottom: 8px;
        @include bp(md-screen) {
            padding: 10px 15px;
            margin-bottom: 0;
        }
    }
  
    .logo {
        display: inline-block;
        overflow: hidden;
        svg {
            width: 159px;
            height: 40px;
            display: inline-block;
        }
    }
    .logo-box{
        .cereus-icon{
            opacity: 0;
            visibility: hidden;
            display: none;
        }
        img{
            height: 45px;
            @include bp(md-screen) {
                height: 40px;
            }
        }
    }
    .k-widget {
        background-color: transparent;
        border: none;
    }
  
    .k-panelbar {
        li.k-item {
            margin-bottom: size(5);
            background-color: transparent;
            white-space: nowrap;
        }
        li.k-state-default {
            .k-link {
                background-color: $white-10;
                border: none;
                background-image: none;
                border-radius: $border-radius-4;
                color: $white;
                box-shadow: none;
                padding: size(8) size(10);
                &:hover {
                    color: $white;
                }
                &:focus {
                    color: $white;
                }
                .custom-icons {
                    font-size: size(20);
                    position: absolute;
                    left: size(15);
                    top: size(16);
                }
                .k-i-arrow-60-down::before {
                    content: "\e92f";
                    font-family: $icons-font-family;
                    font-size: size(17);
                }
                .k-i-arrow-60-up::before {
                    content: "\e900";
                    font-family: $icons-font-family;
                    font-size: size(17);
                }
            }
        }
        li.k-state-active {
            .k-link {
                border-radius: $border-radius-4 $border-radius-4 0 0;
            }
        }
    }
    .k-group.k-panel {
        background-color: transparent;
        padding-left: size(20);
        border-bottom: none;
        border-top: 1px solid $white-10;
        padding-top: size(14);
        padding-bottom: size(14);
        background-color: $white-10;
        border-radius: 0 0 $border-radius-4 $border-radius-4;
        }
    .k-panelbar {
        .k-group {
            li.k-item {
                margin-bottom: size(0);
                white-space: nowrap;
            }
            li.k-state-default {
                .k-link {
                    background-color: transparent;
                    border: none;
                    background-image: none;
                    border-radius: size(4);
                    color: $white-5;
                    box-shadow: none;
                    padding: size(6) size(5) size(6) size(25);
                    position: relative;
                    font-weight: 500;
                    &::before {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        content: "\e92e";
                        font-family: $icons-font-family;
                        transform: rotate(90deg);
                    }
                    &:hover {
                        color: $primary;
                    }
                    &:focus {
                        color: $primary;
                    }
                }
                .k-link.active, .k-state-selected {
                    color: $primary;
                }
            }
        }
    }
  
    .sidebar-text {
        position: relative;
        z-index: 2;
        padding-left: size(36);
    }
    .version-text{
        padding: size(6);
        text-align: center;
        @include transition(all 0.3s ease-in-out);
        p{
            color: $white;
            font-size: size(12);
            line-height: size(15);
            margin-bottom: 0;
        }
        @include bp(xl-screen) {
            display: none;
        }
    }
}
  
.nav-toggle {
    .sidebar-main {
        .k-group.k-panel {
            display: none !important;
        }
        .nav-text {
            font-size: 0px;
            display: none;
        }
        .k-panelbar-expand.k-icon, .k-panelbar-collapse.k-icon {
            display: none;
        }
        .k-link {
            min-height: size(36);
        }
        .custom-icons {
            z-index: 3 !important;
        }
        .version-text{
            display: none;
            @include bp(xl-screen) {
                display: block;
            }
            @include bp(md-screen) {
                display: block;
            }
        }
    }
    .sidebar-main {
        .k-panelbar {
            li.k-state-active {
                .k-link {
                    border-radius: size(4);
                }
            }
        }
        .accordion-collapse {
            display: none;
        }
    }
    .hidden-toggle {
        opacity: 0;
    }
    .logo {
        width: 40px;
        overflow: visible;
        svg {
            width: auto;
            transform: translate(-50px, 0);
        }
    }
    .logo-box{
        .cereus-icon{
            opacity: 1;
            visibility: visible;
            display: block;
        }
        .cereus-logo{
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }
    .page-content-main {
        left: 65px;
        width: calc(100% - 65px);
    }
    // .sidebar-content {
    //     overflow: auto;
    // }
    .sidebar-text {
        font-size: 0px;
        visibility: hidden;
    }
    .toggle-icon {
        right: -4px;
        transform: rotate(-180deg);
    }
    .sidebar-header {
        padding-left: 10px;
        padding-right: 10px;
    }
    .sidebar-main {
        width: 65px;
        overflow: hidden;
    }
    footer {
        margin-left: 65px;
        width: calc(100% - 65px);
    }
    // .accordion-collapse {
    //     display: none;
    // }
    .accordion-button::after {
        display: none;
    }
}
.toggle-icon {
    position: absolute;
    right: 10px;
    top: 21px;
    cursor: pointer;
    padding: 5px 7px;
}
  
.page-content-main {
position: relative;
left: 250px;
width: calc(100% - 250px);
}

.hidden-toggle {
opacity: 1;
}

// iPad View Media Queries

@include bp(xl-screen) {
    .page-content-main {
        left: 65px;
        width: calc(100% - 65px);
    }
    footer {
        margin-left: 65px;
        width: calc(100% - 65px);
    }
    .sidebar-main {
        width: 65px;
        .logo {
            width: 40px;
            svg {
                width: auto;
                transform: translate(-50px, 0);
            }
        }
        .logo-box{
            .cereus-icon{
                opacity: 1;
                visibility: visible;
                display: block;
            }
            .cereus-logo{
                opacity: 0;
                visibility: hidden;
                display: none;
            }
        }
        .sidebar-header {
            padding-left: 10px;
            padding-right: 10px;
        }
        .toggle-icon {
            right: -4px;
            transform: rotate(-180deg);
        }
        .hidden-toggle {
            opacity: 0;
        }
        .sidebar-text {
            font-size: 0px;
            visibility: hidden;
        }
        .accordion-collapse.collapse.show {
            display: none;
        }
        .accordion-button {
            &::after {
                display: none;
            }
        }
    }
    .nav-toggle {
        .responsive-overlay {
            position: fixed;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 999;
            @include transition(all 0.3s ease-in-out);
        }
        .nav-toggle {
            overflow: hidden;
        }
        .sidebar-main {
            width: 250px;
            .accordion-button {
                &::after {
                    display: inline-block;
                }
                &.statick-link {
                    &::after {
                        display: none;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .logo {
                width: 40px;
                svg {
                width: 159px;
                height: 40px;
                transform: translate(0, 0);
                }
            }
            .logo-box{
                .cereus-icon{
                    opacity: 0;
                    visibility: hidden;
                    display: none;
                }
                .cereus-logo{
                    opacity: 1;
                    visibility: visible;
                    display: block;
                }
            }
            .hidden-toggle {
                opacity: 1;
            }
            .sidebar-header {
                padding-left: 20px;
                padding-right: 20px;
            }
            .toggle-icon {
                right: 10px;
                transform: rotate(0deg);
            }
            .sidebar-text {
                font-size: 14px;
                visibility: visible;
            }
            .accordion-collapse.collapse.show {
                display: block;
            }
        }
    }
}
  
// Mobile View media queries
@include bp(md-screen) {
    .page-content-main {
        left: 0px;
        width: calc(100% - 0px);
        padding-bottom: 50px;
    }
    .toggle-icon {
        top: 15px;
    }

    .sidebar-main {
        height: auto;
    }
    .nav-toggle {
        .sidebar-main {
            width: 250px;
            height: 100%;
        }
        .page-content-main {
            left: 0;
            width: calc(100% - 0px);
        }
        .sidebar-content {
            height: calc(100% - 93px);
            width: 100%;
            //margin-top: 12px;
        }
    }
    footer {
        margin-left: 0px;
        display: none;
    }
}

.sub-accordionExample {
    padding: 0px 10px 5px 10px;
}