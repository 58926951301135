.account-pages {
    min-height: 100vh;
    position: relative;
    z-index: 50;
    .container {
        z-index: 2;
        position: relative;
    }
  
    .form-logo {
        margin-bottom: 40px;
        @include bp(md-screen) {
            margin-bottom: 20px;
        }
        img {
            height: 90px;
            margin: 0 auto;
            @include bp(md-screen) {
                height: 70px;
            }
        }
    }
    .account-box {
        background-color: $white;
        width: 100%;
        max-width: 530px;
        border-radius: $border-radius-10;
        box-shadow: 0 size(2) size(10) 0 rgba(0, 0, 0, 0.45);
        margin: size(25) auto;
        position: relative;
    }
  
    .form-bg {
        position: fixed;
        width: 100%;
        height: 100%;
        background-image: url(../../img/account-bg.png);
        background-position: center top;
        background-size: cover;
        left: 0;
        top: 0;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-image: linear-gradient(180deg, rgba(14, 31, 64, 0.49) 0%, rgba(13, 63, 114, 0.91) 100%);
        }
    }
  
    .forgot-link {
        font-size: $text-16;
        margin-bottom: 15px;
        display: inline-block;
        @include bp(md-screen) {
            font-size: $text-14;
            margin: 0px 0 10px;
        }
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            text-decoration: underline;
        }
    }
    .login-form-note {
        background-color: $gray-account;
        border-radius: 0 0 $border-radius-10 $border-radius-10;
        padding: 20px 0;
        @include bp(md-screen) {
            padding: $space-10;
        }
        p {
            font-size: $text-12;
            line-height: 14px;
            color: $dark;
            width: 80%;
            margin: 0 auto;
            @include bp(md-screen) {
                width: 100%;
            }
        }
    }
    .space-30 {
        padding-top: $space-45;
        padding-bottom: $space-40;
        @include bp(md-screen) {
            padding: $space20 $space15;
        }
    }
    .account-box-content {
        min-height: 517px;
        @include bp(md-screen) {
            min-height: inherit;
        }
    }
    .back-btn {
        margin-top: 20px;
        a {
            font-size: $text-16;
            font-weight: 500;
            text-decoration: underline;
        }
    }
    .account-vector {
        margin-bottom: 40px;
        @include bp(md-screen) {
            margin-bottom: 20px;
        }
        svg {
            margin: 0 auto;
            width: 213px;
            height: 184px;
            @include bp(md-screen) {
                width: 170px;
                height: auto;
            }
        }
    }
    p.account-note {
        font-size: $text-16;
        color: $gray;
        margin: 15px 0 0;
        @include bp(md-screen) {
            font-size: $text-14;
        }
    }
    .close-account-box {
        font-size: 20px;
        position: absolute;
        right: 20px;
        top: 15px;
        color: $dark;
        padding: 5px;
    }
}
.form-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url();
    background-position: center top;
    background-size: cover;
    left: 0;
    top: 0;
    z-index: 1;
}
  