@mixin text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin transition($x...){
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}
@mixin dropdownEffect {
    -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; 
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
} 

@keyframes fadein {
    from {    
        -webkit-transform: translate3d(0, 6px, 0);    
        transform: translate3d(0, 6px, 0);    
        opacity: 0;    
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);    
        transform: translate3d(0, 0, 0);    
        opacity: 1;    
    }
}

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}

// Usage Prefix 
// div {
//    @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// }

// Media Querys
@mixin bp($point) {
    @if $point == exl-screen {
        @media (max-width: $break-exl) { @content; } // 1400px
    }
    @if $point == xl-screen {
        @media (max-width: $break-xl) { @content; } // 1200px
        }
    @else if $point == lg-screen {
        @media (max-width: $break-lg) { @content; } // 992px
    }
    @else if $point == md-screen {
        @media (max-width: $break-md)  { @content; } // 767.99px
    }
    @else if $point == small-screen {
        @media (max-width: $break-sm)  { @content; } // 576px
    }
}