// Bootstrap overdies varibles File
@import "assets/scss/common/variables";
// Default Bootstrap scss File no need to change
@import "../node_modules/bootstrap/scss/bootstrap.scss";
// Custom Files
@import "assets/scss/common/all-common";
@import "assets/scss/components/all-components";
@import "assets/scss/pages/all-pages.scss";

body {
    font-family: $font-family-base;
    margin: 0;
    padding: 0;
    font-size:$font-size-sm;
    color: $secondary;
    background-color: $body-background;
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
    // -webkit-font-smoothing: subpixel-antialiased;
    // -webkit-text-stroke: 1px transparent;
}
ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
img {
    max-width: 100%;
}
* {
    @extend %scroll;
}

footer, .hidden-toggle, .page-content-main, .toggle-icon, .logo svg, .sidebar-main{
    @include transition(all 0.3s ease-in-out);
}

// Browser Text Selection Color
::-moz-selection { /* Code for Firefox */
    color: $white;
    background: $primary;
}
::selection {
    color: $white;
    background: $primary;
}
.k-notification-group {
    z-index: 99;
  }
  
.k-treeview-leaf, .k-treeview-leaf .k-treeview-leaf-text{ display: block !important; width: 100% !important; }
.treeview-wrapper{ width: 100%; }
.treeview-wrapper td:nth-child(1){ width: 40%; }
.treeview-wrapper td:nth-child(2){ width: 10%; }
.treeview-wrapper td:nth-child(3){ width: 10%; }
.treeview-wrapper td:nth-child(4){ width: 40%; }

.k-treeview-group td:nth-child(2) .k-radio{ margin-left: 16px; }
.k-treeview-group td:nth-child(3) .k-radio{ margin-left: 23px; }
.k-treeview-group td:nth-child(4) .k-radio{ margin-left: 17px; }

.k-treeview-group .k-treeview-group td:nth-child(2) .k-radio{ margin-left: 9px; }
.k-treeview-group .k-treeview-group td:nth-child(3) .k-radio{ margin-left: 16px; }
.k-treeview-group .k-treeview-group td:nth-child(4) .k-radio{ margin-left: 11px; }

.k-treeview-group .k-treeview-group .k-treeview-group td:nth-child(2) .k-radio{ margin-left: 0px; }
.k-treeview-group .k-treeview-group .k-treeview-group td:nth-child(3) .k-radio{ margin-left: 9px; }
.k-treeview-group .k-treeview-group .k-treeview-group td:nth-child(4) .k-radio{ margin-left: 5px; }

.k-treeview {
    color: #212529;
    overflow-y: auto;
    max-height: calc(100vh - 290px);
}

.k-radio:checked, .k-radio.k-checked {
    border-color: #05B5E4;
    color: white;
    background-color: #05B5E4;
}

.k-switch-on .k-switch-track {
    border-color: #05B5E4;
    background-color: #05B5E4;
}

.k-switch-label-on,
.k-switch-label-off {
    display: inline !important;
}

.k-switch-label-off {
    display: none;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translateY(-50%);
    overflow: hidden;
}
.k-switch-label-on {
    display: none;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    overflow: hidden;
}