@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?ugmx9o');
  src: url('../../fonts/icomoon.eot?ugmx9o#iefix') format('embedded-opentype'), 
  url('../../fonts/icomoon.ttf?ugmx9o') format('truetype'), 
  url('../../fonts/icomoon.woff?ugmx9o') format('woff'), 
  url('../../fonts/icomoon.svg?ugmx9o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.custom-icons {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
  vertical-align: middle;
}

.icon-paper-clip:before {
content: "\e942";
}
.icon-popup:before {
content: "\e941";
}
.icon-doller-window:before {
content: "\e944";
}
.icon-pencil:before {
  content: "\e940";
}
.icon-mike:before {
  content: "\e93a";
}
.icon-mouse:before {
  content: "\e93b";
}
.icon-logout:before {
  content: "\e93d";
}
.icon-settings:before {
  content: "\e93e";
}
.icon-user:before {
  content: "\e93f";
}
.icon-check:before {
  content: "\e939";
}
.icon-arrow-up:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e92f";
}
.icon-arrow-left:before {
  content: "\e934";
}
.icon-arrow-down:before {
  content: "\e938";
}
.icon-briefcase-medical:before {
  content: "\e901";
}
.icon-briefcase:before {
  content: "\e902";
}
.icon-dasktop:before {
  content: "\e903";
}
.icon-dashboard:before {
  content: "\e904";
}
.icon-eye-slash:before {
  content: "\e905";
}
.icon-shield:before {
  content: "\e906";
}
.icon-refresh:before {
  content: "\e907";
}
.icon-lock:before {
  content: "\e908";
}
.icon-user-crop:before {
  content: "\e909";
}
.icon-picture:before {
  content: "\e90a";
}
.icon-list-lg:before {
  content: "\e90b";
}
.icon-close-o .path1:before {
  content: "\e90c";
  color: rgb(0, 0, 0);
}
.icon-close-o .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-check-o .path1:before {
  content: "\e90e";
  color: rgb(0, 0, 0);
}
.icon-check-o .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-expand:before {
  content: "\e910";
}
.icon-compress:before {
  content: "\e911";
}
.icon-user-plus:before {
  content: "\e912";
}
.icon-list-plus:before {
  content: "\e913";
}
.icon-upload-lg:before {
  content: "\e914";
}
.icon-trash:before {
  content: "\e915";
}
.icon-filter:before {
  content: "\e916";
}
.icon-rotate:before {
  content: "\e917";
}
.icon-link:before {
  content: "\e918";
}
.icon-chain:before {
  content: "\e919";
}
.icon-list:before {
  content: "\e91a";
}
.icon-download:before {
  content: "\e91b";
}
.icon-excel:before {
  content: "\e91c";
}
.icon-pdf:before {
  content: "\e91d";
}
.icon-upload:before {
  content: "\e91e";
}
.icon-size-arrow:before {
  content: "\e91f";
}
.icon-plus-small:before {
  content: "\e920";
}
.icon-check-box:before {
  content: "\e921";
}
.icon-clock-2:before {
  content: "\e922";
}
.icon-hour-glass:before {
  content: "\e923";
}
.icon-bulb:before {
  content: "\e924";
}
.icon-wallet:before {
  content: "\e925";
}
.icon-print:before {
  content: "\e926";
}
.icon-clock:before {
  content: "\e927";
}
.icon-calender:before {
  content: "\e928";
}
.icon-edit:before {
  content: "\e929";
}
.icon-close-large:before {
  content: "\e92a";
}
.icon-close-small:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e92c";
}
.icon-long-arrow-down:before {
  content: "\e92d";
}
.icon-long-arrow-up:before {
  content: "\e92e";
}
.icon-nav-arrow .path1:before {
  content: "\e930";
  color: rgb(255, 255, 255);
}
.icon-nav-arrow .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(38, 68, 99);
}
.icon-plus:before {
  content: "\e932";
}
.icon-list-menu:before {
  content: "\e933";
}
.icon-bell:before {
  content: "\e935";
}
.icon-help:before {
  content: "\e936";
}
.icon-search:before {
  content: "\e937";
}
.icon-eye:before {
  content: "\e93c";
}