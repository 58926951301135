.census-section{
    .k-grid{
        .k-grid-container{
            overflow-y: auto;
            max-height: 550px;
        }
    }
    .top-action-btns{
        @include bp(lg-screen) {
            margin-top: size(15);
        }
    }
    .census-msg-box{
      height: calc(100vh - 195px);
      position: relative;
      h5{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
    .common-accordion .accordion-header{
      h3{
        display: flex;
        align-items: center;
      }
      .total-case{
        background-color: $tertiary;
        color: $white;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-4;
        font-size: 12px;
        margin-left: 16px;
      }
    }
    .add-service-ic{
        background-color: $primary;
        border-radius: $border-radius-4;
        color: $white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        width: 20px;
        height: 20px;
        &:hover{
            background-color: darken($primary, 9);
        }
        .custom-icons{
            font-size: 14px;
        }
    }
}
.census-filter{
    ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        @include bp(md-screen) {
            width: 100%;
        }
        li{
            @include bp(md-screen) {
                width: calc(50% - 8px);
            }
            .k-picker{
                min-width: 200px;
                @include bp(md-screen) {
                    min-width: 100%;
                }
            }
            .dropdown-static{
                border:1px solid $gray-fields;
                color: $secondary;
                background-color: $white;
                box-shadow: none;
                border-radius: $border-radius-6;
                padding: 6px 15px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 200px;
                .dropdown-text{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .custom-icons{
                    font-size: 20px;
                    color: $gray;
                    margin-left: 8px;
                }
                @include bp(md-screen) {
                    width: calc(50% - 8px);
                    min-width: 100%;
                }
            }
            .dropdown-static.is-invalid{
                border-color: $danger;
            }
            .filter-actions{
                display: flex;
                align-items: center;
                gap: 15px;
                @include bp(md-screen) {
                    width: 100%;
                    flex-wrap: nowrap;
                    justify-content: inherit;
                    overflow-y: hidden;
                    overflow-x: auto;
                }
                .btn{
                    @include bp(md-screen) {
                        width: 100%;
                    }
                }
            }
            &.filter-btns{
                @include bp(md-screen) {
                    width: 100%;
                }
            }
        }
    }
}
.legend-modal-status{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.daterange-flex-box{
    display: flex;
    gap: 20px;
    .form-group{
        width: 100%;
        position: relative;
        .k-input-inner{
            padding: 0;
            font-size: 14px;
            color: $secondary;
        }
        .custom-icons{
            font-size: 20px;
            color: $gray;
            position: absolute;
            right: 15px;
            top: 43px;
        }
    }
}
.census-facility-multiselect{
    .k-treeview-filter{
        padding: 0;
        margin-bottom: 15px;
    }
    .k-i-search::before{
        content: "\e937";
        font-family: "icomoon" !important;
        font-size: 20px;
        color: $gray;
    }
    // .k-input-inner{
    //     padding: 0 8px;
    // }
    .k-input:focus-within{
        box-shadow: none;
        border-color: $primary;
    }
    .k-treeview-leaf:focus, .k-treeview-leaf.k-focus, .k-input:focus, .k-input.k-focus{
        box-shadow: none;
    }
    .k-treeview-leaf:hover, .k-treeview-leaf.k-hover{
        border-color: transparent;
        color: $secondary;
        background-color: transparent;
    }
    .k-treeview-md .k-treeview-leaf {
        padding: 10px 4px;
        font-size: size(14);
        line-height: size(18);
        color: $secondary;
    }
    .k-treeview-toggle{
        .k-icon{
            font-size: 18px;
            color: $gray;
            &.k-i-collapse::before{
                content: "\e938";
                font-family: "icomoon" !important;
                font-size: 18px;
                color: $gray;
            }
            &.k-i-expand::before{
                content: "\e92f";
                font-family: "icomoon" !important;
                font-size: 18px;
                color: $gray;
            }
        }
    }
    .k-treeview-item{
      > .k-treeview-group{
          padding-left: 20px;
      }
  }
}
