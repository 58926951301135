input::-webkit-input-placeholder {
    color: $gray;
  }
input::-moz-placeholder {
    color: $gray;
}
input::-ms-placeholder {
    color: $gray;
}
input.textbox::-ms-input-placeholder {
    color: $gray;
}
input.textbox::-ms-input-placeholder {
    color: $gray;
}
.form-control::placeholder {
    color: $gray;
}

input.form-control, textarea.form-control, select.form-control, .k-textbox {
    background-color: $white;
    border: 1px solid $gray-fields;
    box-shadow: none;
    font-size: $text-14;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 46px;
    padding: 6px 15px;
    text-overflow: "...";
    border-radius: $border-radius-6;
    color: $secondary;
    &:focus {
        box-shadow: none;
        border: 1px solid $primary;
        outline: none;
    }
    &:focus-visible {
        box-shadow: none;
        border: 1px solid $primary;
        outline: none;
    }
}
.was-validated .form-control:invalid, .form-control.is-invalid, .k-picker.is-invalid, .k-datepicker.is-invalid{
    border-color: $danger;
    box-shadow: none !important;
    outline: none;
}
.k-picker.k-invalid, .k-picker.ng-invalid.ng-touched, .k-picker.ng-invalid.ng-dirty {
    border-color: $danger;
}
.has-error{
    color: $danger;
}
.k-input.k-invalid, .k-input.ng-invalid.ng-touched, .k-input.ng-invalid.ng-dirty{
    border-color: $danger;
    box-shadow: none !important;
}
.invalid-feedback{
    color: $danger;
}
.invalid-error{
  color: $danger;
  font-size: 0.875em;
  margin-top: 0.25rem;
}
.form-group .text-danger, .k-tooltip-validation .text-danger{
    color: $danger;
    font-size: 0.875em;
    margin-top: 0.25rem;
}
input.form-control-lg {
    background-color: $white;
    border: 1px solid $gray-fields;
    box-shadow: none;
    font-size: $text-18;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    padding: size(6) size(20);
    text-overflow: "...";
    border-radius: $border-radius-6;
    color: $secondary;
    width: 100%;
    @include bp(md-screen) {
        height: 40px;
        font-size: 16px;
        padding: 6px 15px;
    }
}
textarea.form-control {
    height: 70px;
    white-space: normal;
    overflow: visible;
    resize: none;
}
.form-control:disabled, .form-control[readonly]{
    background-color: $gray-account;
    color: $gray;
}
.k-disabled{
    background-color: $gray-account;
    color: $gray;
    opacity: 1;
    filter: none;
    &.k-picker{
        background-color: $gray-account;
        color: $gray;
        .k-input-inner{
            background-color: $gray-account;
            color: $gray;
        }
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// new style start
// .form-group {
//     margin-bottom: $space25;
// }
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
    outline: none !important;
}
.bootstrap-select .dropdown-toggle::after {
    border: none;
    content: "\e938";
    font-family: $icons-font-family;
    font-size: 18px;
    color: $gray;
}
.dropup .dropdown-toggle::after {
    border: none;
    content: "\e900";
    font-family: $icons-font-family;
    font-size: 18px;
    color: $gray;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
.bootstrap-select {
    width: 100%;
}
.bootstrap-select .dropdown-toggle {
    background-color: $white;
    border: 1px solid $gray-fields;
    box-shadow: none;
    font-size: 14px;
    white-space: nowrap;
    height: auto;
    padding: 9px 20px;
    border-radius: 6px;
    color: $secondary;
    outline: none;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bootstrap-select .dropdown-toggle .filter-option {
    padding-right: 10px;
    color: $gray;
}
.bootstrap-select .dropdown-menu li a {
    color: $gray;
    padding: 10px 20px;
    font-size: 14px;
}
.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active,
.bootstrap-select .dropdown-item:hover,
.bootstrap-select .dropdown-item:focus {
    background-color: #d9edf6;
    color: $gray;
}
.bootstrap-select-small {
    width: 100%;
}
.bootstrap-select-small .dropdown-toggle {
    background-color: $white;
    border: 1px solid $gray-fields;
    box-shadow: none;
    font-size: 14px;
    white-space: nowrap;
    height: 40px;
    padding: 8px 20px;
    border-radius: 6px;
    color: $secondary;
    outline: none;
}

.bootstrap-select-lg .dropdown-toggle {
    background-color: $white;
    border: 1px solid $gray-fields;
    box-shadow: none;
    font-size: 18px;
    white-space: nowrap;
    height: auto;
    padding: 11px 20px;
    border-radius: 6px;
    color: $gray;
    outline: none;
}
.bootstrap-select-lg .dropdown-menu li a {
    color: $gray;
    padding: 16px 20px;
    font-size: 18px;
}
.bootstrap-select-lg .dropdown-item.active,
.bootstrap-select-lg .dropdown-item:active,
.bootstrap-select-lg .dropdown-item:hover,
.bootstrap-select-lg .dropdown-item:focus {
    background-color: $gray-account;
    color: $secondary;
}

.form-group {
    margin-bottom: 20px;
    @include bp(md-screen) {
        margin-bottom: 15px;
    }
}
.field-required{
    color: $danger;
}
.input-group-icon{
    position: relative;
    .custom-icons{
        position: absolute;
        top: 14px;
        right: 15px;
        font-size: 18px;
        color: $gray;
        &.icon-eye{
            font-size: 22px;
            top: 12px;
            right: 12px;
        }
        &:hover{
            color: $primary;
        }
    }
    .form-control{
        padding: 6px 40px 6px 15px;
    }
}
.k-checkbox[type="checkbox"] {
    width: 1.43em;
    height: 1.43em;
    border: 1px solid $gray;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
}
.k-checkbox:checked[type="checkbox"] {
    background-color: $primary;
    border-color: $primary;
}
.k-checkbox:focus {
    box-shadow: none;
}
.k-checkbox:checked[type="checkbox"] {
    background-image: none;
    text-align: center;
    line-height: 18px;
}
.k-checkbox:checked:focus, .k-checkbox.k-checked.k-state-focus{
    box-shadow: none;
}
.k-checkbox:checked[type="checkbox"]::after {
    content: "\e939";
    font-family: $icons-font-family;
    color: $white;
    font-size: 11px;
}
.check-all .form-check-input:checked[type="checkbox"]::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $primary;
    display: inline-block;
}
.check-all .form-check-input:checked[type="checkbox"] {
    background-color: transparent;
    line-height: 16px;
}
.bootstrap-select .dropdown-menu {
    -webkit-animation: inherit;
    animation: inherit;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-right: none;
}
.input-group-text {
    background-color: $white;
    border-color: $gray-fields;
    border-radius: 6px;
    font-size: 20px;
    color: $gray;
}

.form-label sup, .common-box h4 sup {
    color: #ff2525;
}
.form-label, label{
    color: $secondary;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
}
.only-check {
    padding: 0;
    margin: 0;
}
.only-check .form-check-input {
    margin: 0;
}

// Switch
.button-switch {
    font-size: $text-12;
    height: 31px;
    position: relative;
    font-weight: $font-medium;
    width: 95px;
    display: inline-block;
    .switch {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 0;
        font-size: 1em;
        left: 0;
        line-height: 0;
        outline: none;
        position: absolute;
        top: 0;
        width: 0;
        &::before {
            content: "";
            font-size: $text-12;
            position: absolute;
            border-radius: 20px;
            background: $primary-light;
            height: 31px;
            left: 0;
            top: 0;
            transition: background-color 0.25s ease-out 0.1s;
            width: 95px;
        }
        &::after {
            content: "";
            font-size: $text-12;
            position: absolute;
            border-radius: 50%;
            transform: translate(0, 0);
            transition: transform 0.25s ease-out 0.1s;
            background-color: $white;
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
            width: 27px;
            height: 27px;
            left: 3px;
            margin-top: 2px;
        }
        &:checked:after {
            transform: translate(66px, 0);
            left: -1px;
            background-color: $tertiary;
        }
        &:checked ~ .lbl-off {
            opacity: 0;
        }
        &:checked ~ .lbl-on {
            opacity: 1;
        }
    }
    .lbl-off, .lbl-on {
        cursor: pointer;
        display: block;
        font-size: $text-12;
        font-weight: $font-medium;
        line-height: 19px;
        position: absolute;
        top: 0.5em;
        transition: opacity 0.25s ease-out 0.1s;
        color: $tertiary;
    }
    .lbl-off {
        right: 15px;
    }
    .lbl-on {
        opacity: 0;
        left: 11px;
    }
    &.yes-no-switch{
        width: 70px;
        .switch{
            &::before{
                width: 70px;
            }
            &:checked:after{
                transform: translate(41px, 0);
            }
        }
        .lbl-off{
            right: 14px;
        }
        .lbl-on{
            left: 12px;
        }
    }
}

.custom-file {
    position: relative;
    cursor: pointer;
    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        font-size: 300px;
        z-index: 9;
    }
}
.primary-custom-file{
    border: 1px solid $primary;
    position: relative;
    //overflow: visible !important;
    margin-bottom: 15px;
    object-fit: cover;
    .icon-picture{
        color: $primary !important;
    }
    .upload-icon-file{
        background-color: $primary;
        width: 24px;
        height: 24px;
        border-radius: $rounded-pill;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 6px;
        bottom: 6px;
        .icon-upload-lg{
            color: $white;
        }
    }
}
.pw-field {
    position: relative;
    .form-control {
        padding-right: size(50);
    }
    .pw-eye {
        position: absolute;
        right: size(10);
        top: 5px;
        padding: size(1) size(5);
        cursor: pointer;
        font-size: $text-24;
        color: $gray;
        @include bp(md-screen) {
            top: 0px;
        }
    }
    .eye-icon {
        display: inline-block;
    }
    .eye-slash-icon {
        display: none;
    }
    .form-control.is-invalid {
        background-image: none;
    }
    .was-validated .form-control:invalid {
        background-image: none;
    }
}
.pw-field.show-pw {
    .eye-icon {
        display: none;
    }
    .eye-slash-icon {
        display: inline-block;
    }
}

// Kendo Design START
.k-dropdown {
    width: 100%;
    border-color: $gray-fields;
    color: $secondary;
    background-color: $white;
    box-shadow: none;
    border-radius: $border-radius-6;
    &:hover{
        background-color: $white;
        box-shadow: none;
        border-color: $primary;
    }
    &:focus{
        background-color: $white;
        box-shadow: none;
        border-color: $primary;
    }
    &:focus-within {
        border-color: $gray-fields;
        background-color: $white;
        box-shadow: none;
    }
    .k-dropdown-wrap {
        background-color: $white;
        font-size: $text-14;
        .k-select {
            padding-right: 15px;
            color: $gray;
        }
        &:hover{
            background-color: $white;
        }
        &:focus{
            background-color: $white;
            box-shadow: none;
        }
        &.k-state-focused{
            background-color: $white;
            box-shadow: none;
            border-color: $primary;
        }
    }
}
.k-state-focused.k-state-selected.k-list-optionlabel{
    box-shadow: none;
}
.k-state-selected.k-list-optionlabel{
    background-color: $primary;
    box-shadow: none;
    border-color: $primary;
    color: $white;
    padding: 6px 14px;
}
.k-list-optionlabel{
    padding: 6px 14px;
}
.k-list-optionlabel:hover.k-state-selected, .k-list-optionlabel:hover{
    color: $secondary;
    background-color: $primary-light;
}
.k-animation-container-shown, .k-animation-container{
    .k-popup{
        border: 1px solid $gray-fields;
        border-radius: $border-radius-6;
        color: $secondary;
        font-size: $text-14;
        overflow: hidden;
        padding: 6px 0;
    }
}
.k-animation-container{
    border-radius: $border-radius-6;
}
.k-list{
    .k-state-focused.k-item{
        box-shadow: none;
        &:hover{
            box-shadow: none;
        }
    }
    .k-item{
        padding: 6px 14px;
        &:hover{
            color: $secondary;
            background-color: $primary-light;
        }
    }
}
.k-list .k-state-selected.k-item, .k-list .k-item:hover.k-state-selected {
    color: $white;
    background-color: $primary;
    box-shadow: none;
}
.k-list .k-state-focused.k-state-selected.k-item{
    box-shadow: none;
}
.k-input {
    height: 46px;
    padding: 6px 15px;
    &:focus, &.k-focus{
        background-color: $white;
        box-shadow: none;
        border-color: $gray-fields;
    }
}
.k-i-arrow-s {
    &::before {
        font-family: $icons-font-family;
        font-size: $text-20;
        content: "\e938";
    }
}
.k-i-arrow-n {
    &::before {
        font-family: $icons-font-family;
        font-size: $text-20;
        content: "\e900";
    }
}
.k-checkbox:indeterminate, .k-checkbox.k-indeterminate, .k-checkbox.k-state-indeterminate{
    background-image: url(../../img/checkbox-dash-ic.svg);
}
.common-color-table .k-checkbox:indeterminate, .common-color-table .k-checkbox.k-indeterminate, .common-color-table .k-checkbox.k-state-indeterminate{
    background-image: url(../../img/checkbox-dash-white-ic.svg);
}
.color-table .k-checkbox:indeterminate, .color-table .k-checkbox.k-indeterminate, .color-table .k-checkbox.k-state-indeterminate{
    background-image: none;
}
// K-Picker Dropdown
.k-picker {
    width: 100%;
    border-color: $gray-fields;
    color: $secondary;
    background-color: $white;
    box-shadow: none;
    border-radius: $border-radius-6;
    font-family: $font-family-base;
    @include transition(all 0.3s ease-in-out);
    &:hover{
        background-color: $white;
        box-shadow: none;
        border-color: $gray-fields;
    }
    &:focus{
        background-color: $white;
        box-shadow: none;
        border-color: $gray-fields;
    }
    &:focus-within {
        border-color: $gray-fields;
        background-color: $white;
        box-shadow: none !important;
    }
    &.is-invalid{
        border-color: $danger;
    }
    .k-input-inner {
        background-color: $white;
        font-size: $text-14;
        height: 44px;
        padding: 6px 15px;
        &:hover{
            background-color: $white;
        }
        &:focus{
            background-color: $white;
            box-shadow: none;
        }
    }
    .k-input-button {
        padding-right: 15px;
        color: $gray;
    }
}
.k-focus.k-selected.k-list-optionlabel{
    box-shadow: none;
}
.k-selected.k-list-optionlabel{
    background-color: $primary;
    box-shadow: none;
    border-color: $primary;
    color: $white;
    padding: 6px 14px;
}
.k-list-optionlabel{
    padding: 6px 14px;
}
.k-list-optionlabel:hover.k-selected, .k-list-optionlabel:hover{
    color: $secondary;
    background-color: $primary-light;
}
.k-list{
    .k-focus.k-list-item{
        box-shadow: none;
        &:hover{
            box-shadow: none;
        }
    }
    .k-list-item, .k-list-optionlabel{
        padding: 6px 14px;
        gap: 0;
        font-size: 14px;
        &:hover{
            color: $secondary;
            background-color: $primary-light;
        }
    }
}
.k-list .k-selected.k-list-item, .k-list .k-list-item:hover.k-selected {
    color: $white;
    background-color: $primary;
    box-shadow: none;
}
.k-list .k-focus.k-selected.k-list-item{
    box-shadow: none;
}
.dropdownlist-border-remove{
    border: none;
    background-color: transparent;
    .k-input-inner{
        background-color: transparent;
        height: 32px;
        padding: 4px 0;
    }
    .k-input-button{
        padding:4px 0 4px 6px;
    }
    &:hover{
        background-color: transparent;
        box-shadow: none;
    }
    &:focus{
        background-color: transparent;;
        box-shadow: none;
    }
    &:focus-within {
        background-color: transparent;;
        box-shadow: none !important;
    }
    .k-input-inner {
        background-color: transparent;
        &:hover{
            background-color: transparent;
        }
        &:focus{
            background-color: transparent;
            box-shadow: none;
        }
    }
}

// Old Styles
.form-check-input[type="checkbox"] {
    width: 1.43em;
    height: 1.43em;
    border: 1px solid $gray;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
}
.form-check-input:checked[type="checkbox"] {
    background-color: $primary;
    border-color: $primary;
}
.form-check-input:focus {
    box-shadow: none;
}
.form-check-input:checked[type="checkbox"] {
    background-image: none;
    text-align: center;
    line-height: 18px;
}
.form-check-input:checked[type="checkbox"]::after {
    content: "\e939";
    font-family: $icons-font-family;
    color: $white;
    font-size: 11px;
}
.check-all .form-check-input:checked[type="checkbox"]::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $primary;
    display: inline-block;
}
.check-all .form-check-input:checked[type="checkbox"] {
    background-color: transparent;
    line-height: 16px;
}
.bootstrap-select .dropdown-menu {
    -webkit-animation: inherit;
    animation: inherit;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-right: none;
}
.input-group-text {
    background-color: $white;
    border-color: $gray-fields;
    border-radius: 6px;
    font-size: 18px;
    color: $gray;
}

.form-label sup, .common-box h4 sup {
    color: #ff2525;
}
.only-check {
    padding: 0;
    margin: 0;
}
.only-check .form-check-input {
    margin: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.k-pager-numbers-wrap{
    select.k-pager-nav, select.k-pager-nav.k-dropdown-list, select.k-dropdown-list{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(../../img/arrow-down.svg);
        background-position: center right 7px;
        background-size: 25%;
        background-repeat: no-repeat;
        padding: 6px 24px 6px 10px;
        border-color: $gray-fields;
        border-radius: $border-radius-6;
        text-align: center;
    }
}

/*==Custom Radio & Checkbox ==*/
.custom-checkbox{
    &.vertical{
        label{
            display: block;
        }
    }
    label{
        margin:0 10px 10px 0;
        padding: 0 0 0 30px;
        font-size: 14px;
        line-height: 20px;
        color: $secondary;
        cursor: pointer;
        position: relative;
        display:inline-block
    }
    input[type="checkbox"]{
        display: none;
        + span {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            background: $white;
            border: 1px solid $gray;
            border-radius: $border-radius;
        }
        &:indeterminate {
            + span {
                // border: 1px solid $primary;
                // background: $primary;
                @include css3-prefix(transition, all 0.3s);
            }
            ~ span:after{
                top: 50%;
                left: 50%;
                margin-left: -3px;
                margin-top: -7px;
                width: 6px;
                height: 11px;
                border-width: 0 3px 0px 0 !important;
                display: block;
                border: solid $primary;
                transform: rotate(90deg);
            }
        }
        &:checked{
            + span {
                border: 1px solid $primary;
                background: $primary;
                @include css3-prefix(transition, all 0.3s);
            }
            ~ span:after{
                top: 50%;
                left: 50%;
                margin-left: -2px;
                margin-top: -7px;
                width: 5px;
                height: 10px;
                border-width: 0 1px 1px 0 !important;
                display: block;
                border: solid $white;
                @include css3-prefix(transform, rotate(45deg));
            }
        }
        ~ span:after{
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled{
            + span {
                opacity: 0.7;
            }
        }
    }

}
.custom-radio{
    &.vertical{
        label{
            display: block;
        }
    }
    label{
        margin:0 10px 10px 0;
        padding: 0 0 0 30px;
        font-size: 14px;
        line-height: 20px;
        color: $secondary;
        cursor: pointer;
        position: relative;
        display:inline-block
    }
    input[type="radio"]{
        display: none;
        + span{
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            background: $white;
            border: 1px solid $gray;
            border-radius: 50%;
        }
        ~ span:after {
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled{
            + span {
                opacity: 0.7;
            }
        }
        &:checked{
            + span {
                background: $primary;
                border: 1px solid $primary;
                @include css3-prefix(transition, all 0.3s);
            }
            ~ span:after{
                top: 50%;
                left: 50%;
                margin-left: -4px;
                margin-top: -4px;
                width: 8px;
                height: 8px;
                display: block;
                background: $white;
                @include border-radius(50%);
            }
        }
    }
}
.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
  display: inline-block;
}
.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

.k-datepicker, .k-timepicker, .k-datetimepicker{
    width: 100%;
    color: $secondary;
    border-color:$gray-fields;
    border-radius: $border-radius-6;
    font-family: $font-family-base;
    font-size: $text-14;
    &:focus, &:focus-within{
        border-color: $primary;
        color: $secondary;
        box-shadow: none;
    }
    &.k-focus{
        border-color: $primary;
        color: $secondary;
        box-shadow: none;
    }
    .k-input{
        padding: 0;
        height: auto;
        &:focus, &:focus-within{
            box-shadow: none;
        }
    }
    .k-input-inner{
        padding: 0 6px 0 0;
        &:focus{
            box-shadow: none;
        }
    }
    .k-input-button{
        padding: 0;
        background-color: transparent;
        border: none;
        .k-i-calendar{
            &::before{
                font-family: "icomoon";
                content: "\e928";
                font-size: 18px;
            }
        }
        .k-i-clock{
            &::before{
                font-family: "icomoon";
                content: "\e927";
                font-size: 18px;
            }
        }
        &:hover{
            border-color: transparent;
            background-color: $white;
            .k-i-calendar, .k-i-clock{
                &::before{
                    color: $primary;
                }
            }
        }
    }
    .k-select{
        background-color: $white;
        border: none;
        color: $gray;
        padding: 6px 15px 6px 6px;
        .k-i-calendar{
            &::before{
                font-family: "icomoon";
                content: "\e928";
                font-size: 18px;
            }
        }
        &:hover{
            border-color: transparent;
            background-color: $white;
            .k-i-calendar{
                &::before{
                    color: $primary;
                }
            }
        }
        &.k-state-hover{
            border-color: transparent;
            background-color: $white;
        }
    }
}

.k-calendar{
    font-family: $font-family-base;
    color: $secondary;
    border-color:$gray-fields;
    border-radius: $border-radius-6;
    font-size: $text-14;
    .k-calendar-title{
        font-weight: $font-medium;
        font-family: $font-family-base;
        color: $secondary;
        @include transition(all 0.3s ease-in-out);
        &:hover{
            background-color: $primary-light !important;
            color: $primary;
        }
        &::before{
            display: none;
        }
    }
    .k-nav-today{
        color: $secondary;
        font-weight: $font-medium;
        @include transition(all 0.3s ease-in-out);
        &:hover{
            color: $primary;
        }
    }
    .k-calendar-nav{
        .k-icon-button{
            padding: 6px;
            @include transition(all 0.3s ease-in-out);
            &:hover{
                background-color: $primary-light !important;
                color: $primary;
            }
            &::before{
                display: none;
            }
        }
    }
    .k-calendar-th{
        color: $secondary;
        font-weight: $font-medium;
        font-size: $text-14;
    }
    .k-other-month{
        color: $gray;
        border-radius: $border-radius-6;
    }
    .k-link{
        border-radius: $border-radius-6;
    }
    .k-calendar-view{
        .k-today{
            .k-link{
                box-shadow: inset 0 0 0 1px $primary;
                color: $primary;
            }
            &.k-state-focused{
                .k-link{
                    box-shadow: inset 0 0 0 1px $primary;
                }
            }
        }
    }
    .k-calendar-td{
        &.k-state-selected{
            .k-link{
                border-color: $primary;
                background-color: $primary;
                color: $white;
            }
            &:hover{
                .k-link{
                    border-color: $primary;
                    background-color: $primary;
                    color: $white;
                }
            }
            &.k-state-focused, &.k-state-focus{
                .k-link{
                    box-shadow: none;
                }
            }
        }
        &:hover{
            .k-link{
                border-color: $primary-light;
                color: $primary;
                background-color: $primary-light;
            }
        }
        &.k-state-hover{
            .k-link{
                border-color: $primary-light;
                color: $primary;
                background-color: $primary-light;
            }
        }
    }
}
.k-upload{
    border-style: dashed;
    border-color: $gray-fields;
    border-radius: $border-radius-6;
    color: $secondary;
    font-family: $font-family-base;
    font-size: $text-14;
    .k-dropzone{
        border-color: $gray-fields;
        color: $secondary;
        background-color: $white;
        border-radius: $border-radius-6;
        flex-direction: row-reverse;
        padding: 6px 6px 6px 15px;
        .k-dropzone-hint{
            color: $tertiary;
            flex: inherit;
        }
        .k-button{
            background-color: $primary-light;
            border-color: $primary-light;
            color: $secondary;
            font-weight: $font-medium;
            border-radius: $border-radius-6;
            &:hover{
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
        }
    }
    .k-upload-files{
        border-color: $gray-fields;
        .k-file{
            padding: 8px 15px;
            &.k-state-focused{
                box-shadow: none;
            }
        }
        .k-upload-action{
            padding: 0;
            color: $gray;
            &:hover{
                color: $primary;
            }
            .k-icon{
                font-size: 18px;
            }
        }
        .k-file-name{
            font-size: 12px;
            color: $secondary;
        }
    }
}

// Date range box
// .date-range-box{
//     display: flex;
//     align-items: flex-start;
//     flex-wrap: nowrap;
//     gap: 15px;
//     .to-text{
//         font-size: size(14);
//         color: $gray;
//         height: 44px;
//         display: flex;
//         align-items: center;
//     }
//     .filter-btn{
//         .btn{
//             padding: 10px 16px;
//         }
//     }
//     .datepicker-box{
//         width: 100%;
//     }
// }
.date-range-box{
    border: 1px solid $border-color;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    max-width: 260px;
    padding: size(0) size(15) size(0) size(15);
    .k-input{
        border: none;
        padding: 0;
        width: 78px;
        height: auto;
    }
    .k-input-inner{
        padding: size(12) 0;
        font-size: 14px;
        color: $secondary;
    }
    .dash-range{
        padding: 0 size(6);
    }
    &.custom-range-box{
        // display: flex;
        // justify-content: space-between;
        // max-width: 100%;
        // background-color: #ffffff;
        // border: 1px solid #DDDDDD;
        // box-shadow: none;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // height: size(46);
        // padding: size(6) size(15);
        // text-overflow: "...";
        border-radius: size(6);
        color: #122B45;
        // .datepicker-box{
        //     flex: 0 1 100%;
           
        //         display: flex;
        //         justify-content: center;
           
        //     .k-input{
        //         width: 100%;
        //     }
        // }



        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 20px;
        max-width: 100%;
        .to-text{
            font-size: size(14);
            color: $gray;
            height: 44px;
            display: flex;
            align-items: center;
        }
        .filter-btn{
            .btn{
                padding: 10px 16px;
            }
        }
        .datepicker-box{
            width: 100%;
            .k-input{
                width: 100%;
                .k-input-inner{
                    text-align: center;
                    margin: 0 10px;
                }
            }
        }

    }
    &:not(.custom-range-box){
        padding: size(0) size(50) size(0) size(15);
        &::after{
            font-family: 'icomoon';
            content: "\e928";
            font-size: size(20);
            color: $gray;
            position: absolute;
            right: 16px;
            @include transition(all 0.3s ease-in-out);
        }
    }
    &:hover{
        &::after{
            color: $primary;
        }
    }
}
.k-time-header .k-time-now, .k-time-selector-header .k-time-now{
    color: $primary;
    &:hover{
        color: darken($primary, 9);
    }
}
.k-time-list .k-item:hover{
    color: $primary;
}

// Label with icon
.label-with-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .icon-box{
        width: 20px;
        height: 20px;
        border-radius: $border-radius;
        background-color: $tertiary;
        padding: 3px;
        color: $white;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background-color: darken($tertiary, 9);
        }
    }
    .form-label, label{
        margin-bottom: 0;
    }
}

// Multi Select
.k-multiselect{
    height: auto;
    .k-chip-solid-base{
        border-color:$primary-light;
        background-color: $primary-light;
        color: $secondary;
        font-size: size(14);
    }
    &.is-invalid{
        border-color: $danger;
    }
}

.is-notadmitted{
    background-color: lightgray;
    border: 2px solid lightgray;
    padding: 21px !important;
}