footer {
    margin: 0 0 0 250px;
    background-color: $dark;
    padding: 6px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 250px);
    z-index: 99;
    @include bp(md-screen) {
        position: relative;
    }
    .copyright-msg {
        font-size: $text-10;
        line-height: 15px;
        margin: 0;
        color: $white;
    }
}
