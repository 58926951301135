.left-account-info {
    background-color: $gray-account; 
    padding: $space20 $space-10; 
    min-height: 100%; 
    text-align: center; 
    position: relative;
    @include bp(lg-screen) {
        min-height: 100%;
    }
    &.only-profile{
        @include bp(lg-screen) {
            min-height: 100%;
        }
    }
    h4 {
        font-size: $text-14; 
        font-weight: $font-regular;
        line-height: 22px;
    }
    h5 {
        font-size: $text-14; 
        font-weight: $font-regular; 
        margin: 10px 0 0; 
        color: $gray;
        line-height: 22px;
    }
    p {
        position: absolute; 
        left: 0; 
        bottom: 0px; 
        width: 100%;
        @include bp(lg-screen) {
            position: relative;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    .btn-link{
        color: $primary;
        text-decoration: none;
        &:hover{
            color: darken($primary, 9);
        }
    }
}
.circle-120 {
    @extend %circle-120;
}