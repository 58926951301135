/* Backgrouind image*/
$assetPath: "../img/";
/* background-image: url(#{$assetPath} + "/expenses-checkmark.png"); */

/* Theme colors variables */
$white:    	#ffffff;
$primary: 	#05B5E4;
$secondary:	#122B45;
$tertiary:	#4297BF;
$black:     #060707;
$dark:    	#0B1219;
$gray:      #929DA9;
$primary-light:#D9EDF6;
$light-sky :#E0F4F9;

/* Label colors variables */
$success:  	#00B050;
$info:     	#62CBE6;
$warning: 	#C79706;
$danger:  	#FF2525;
$light:   	#888686;
$count-status:#FB7979;
$success-light:#A9D08E;
$yellow: #ffff00;
$darkgreen: #156d3d;

/* Gray Colors variables */
$gray-body: #F9F9F9;
$gray-account:#F2F2F2; 
$gray-fields:#DDDDDD;
$gray-tabs: #DFDFDF; 
$box-gray:#DCDCDC;
$box-bottom:#E5EDEF;
$gray-pgn-border: #E1E6EF;
$gray-pgn:#656565; 
$common-box-border:#DADADA;
$border-color: #EFEFEF;
$grid-alt: #ECF5FA;

/* transparent variables */
$white-10: rgba(255,255,255,0.10);
$white-5: rgba(255,255,255,0.5);

/* Section variables */
$body-background: $gray-body;
$body-text-color: $dark;
$font-family-base: 'Poppins', sans-serif;
$icons-font-family: 'icomoon' !important;
$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
$font-custom:16;
$font-size-sm: $font-size-base * .875; //14px

/* Style Link Elements.*/
$link-color:theme-color("primary");
$link-decoration:none;
$link-hover-color:darken($primary, 50%);
$link-hover-decoration:underline;

$border-radius-50: 50%;
$border-radius-12: 12px;
$border-radius-10: 10px;
$border-radius-8: 8px;
$border-radius-6: 6px;
$border-radius-4: 4px;
$border-radius-2: 2px;
$rounded-pill: 100px;

/* Spaces */
$space-50:50px;
$space-45:45px;
$space-40:40px;
$space30: 30px;
$space15: 15px;
$space20: 20px;
$space25: 25px;
$space-10:10px;
$box-shadow-0:none;

/* Typography */
$text-10:10px;
$text-12:12px;
$text-13:13px;
$text-14:14px;
$text-16:16px;
$text-18:18px;
$text-20:20px;
$text-24:24px;

$font-light: 300;
$font-regular:400;
$font-medium:500;
$font-semibold: 600;
$font-bold: 700;

// Animations
$transition:all 0.3s ease-in-out;

/* Media Queries */
$break-exl: 1400px; // exl-screen
$break-xl: 1200px; // xl-screen
$break-lg: 992px; // lg-screen
$break-md: 767.99px; // md-screen
$break-sm: 576px; // small-screen



   


