.modal{
    z-index: 99999;
}
.modal-backdrop{
    z-index: 9999;
}
.common-popup-info{
    h6{
        color: $primary;
        margin-bottom: 10px;
    }
}