.view-vob-section{
    .white-box-height{
        height: calc(100vh - 190px);
        overflow: auto;
        @include bp(lg-screen) {
            height: calc(100vh - 202px);
        }
    }
    .vob-information{
        margin-top: size(20);
        height: calc(100% - 20px);
        @include bp(xl-screen) {
            height: auto;
        }
        @include bp(md-screen) {
            margin-top: size(15);
        }
        h6{
            font-weight: $font-semibold;
            margin-bottom: size(10);
        }
        .common-white-box{
            height: calc(100% - 30px);
            @include bp(xl-screen) {
                height: auto;
            }
            @include bp(md-screen) {
                margin-top: size(15);
            }
            .btn{
                @include bp(md-screen) {
                    width: 100%;
                }
            }
            &.vob-note-height-btn-title{
                height: calc(100% - 43px);
            }
        }
        .notes-height{
            max-height: 70dvh;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .note-mr-btm{
            margin-bottom: size(15);
        }
        .vob-note-box{
            border-bottom: 1px solid $box-bottom;
            margin-bottom: size(15);
            padding-bottom: size(15);
            position: relative;
            &:last-child{
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            p{
                margin-bottom: size(10);
                word-break: break-all;
                padding-right: 20px;
            }
            .date-time-text{
                font-size: size(12);
                color: $gray;
                font-weight: $font-regular;
                display: block;
            }
        }
        .vob-info-details{
            //height: calc(100% - 20px);
            height: 100%;
            @include bp(xl-screen) {
                height: auto;
            }
        }
    }
    .vob-info-actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: size(10);
        gap: 10px;
        @include bp(md-screen) {
            width: 100%;
            flex-direction: column;
        }
        h6{
            margin-bottom: 0;
        }
        .form-group{
            @include bp(md-screen) {
                width: 100%;
            }
        }
        .info-title{
            @include bp(md-screen) {
                width: 100%;
            }
        }
        .info-action{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            @include bp(md-screen) {
                width: 100%;
            }
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                .custom-icons{
                    margin-right: size(6);
                    font-size: size(20);
                }
                @include bp(md-screen) {
                    width: 100%;
                }
            }
            .k-picker{
                min-width: 150px;
                @include bp(md-screen) {
                    min-width: 100%;
                }
            }
            .action-box{
                display: flex;
                gap: 15px;
                @include bp(md-screen) {
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
    }
}
.note-action-dropdown{
    position: absolute;
    right: 0;
    top: 0;
}
.add-vob-form{
    .common-primary-box{
        margin-bottom: 20px;
        height: calc(100% - 20px);
        @include bp(lg-screen) {
            height: auto;
        }
        @include bp(md-screen) {
            margin-bottom: 15px;
            height: auto;
        }
        .text-regular{
            margin-bottom: 0;
            margin-top: size(15);
            font-weight: $font-medium;
            color: $gray;
        }
        .text-italic{
            margin-bottom: 0;
            margin-top: size(15);
            font-weight: $font-medium;
            color: $gray;
            font-style: italic;
        }
    }
    .vob-detail-row{
        h6{
            margin-bottom: size(15);
        }
    }
    .address-height-159{
        height: 159px;
        @include bp(lg-screen) {
            height: 70px;
        }
    }
    .address-height-184{
        height: 184px;
        @include bp(lg-screen) {
            height: 70px;
        }
    }
}
.view-tpn-grid-height{
    .k-grid{
        height: 376px;
        overflow: auto;
    }
}
.view-tpn-note-height{
    max-height: 897px;
    overflow-y: auto;
    overflow-x: hidden;
}
.view-collection-grid-height{
    .k-grid{
        height: 334px;
        overflow: auto;

    }
    &.view-billing{
        .k-grid{
            height: calc(100vh - 350px);
        }
    }

}
.view-collection-grid-height{
    .k-grid{
        height: 334px;
        overflow: auto;

    }
    &.view-vobinfo{
        .k-grid{
            height: calc(100vh - 650px);
        }
    }

}
.view-collection-note-height{
    height: 588px;
    overflow-x: hidden;
    overflow-y: auto;
    &.view-billing{
        height: calc(100vh - 314px);
        @include bp(xl-screen) {
            height: calc(100vh - 314px) !important;
        }
    }
}

.vob-statistics{
    .statistics-filter{
        margin-bottom: 20px;
        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            li{
                .k-picker {
                    min-width: 200px;
                }
            }
        }
    }
    .status-up-icon{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .k-icon{
            margin-right: 6px;
        }
    }
}
.statistics-grid-height-lg{
    height: 800px;
    overflow: auto;
}

.statistics-grid-height-md{
    .k-grid{
        height: 319px;
        overflow: auto;
    }
}
.custom-checkbox-disabled {
  .k-checkbox {
    // pointer-events: none;
    // opacity: 0.6;
    visibility: hidden;
  }
}
