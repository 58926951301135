@import "mixins";
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	margin-bottom: 0px;
}
/*Headings*/
h1, .h1{
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size:22px; 
	line-height:30px;
	color:$secondary;
	@include bp(md-screen) {
		font-size: 18px;
		line-height: 26px;
	}
}
h2, .h2 { 
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size:22px; 
	line-height:30px;
	color:$secondary;
}
h3, .h3 { 
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size:20px; 
	line-height:28px;
	color:$secondary;
}
h4, .h4 { 
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size:18px; 
	line-height:26px;
	color:$secondary;
}
h5, .h5 { 
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size:16px; 
	line-height:24px;
	color:$secondary;
}
h6, .h6 { 
	font-family: $font-family-base;
	font-weight: $font-medium;
	font-size: 14px;
	line-height: 22px;
	color:$secondary;
}

p{
	font-family: $font-family-base;
	font-weight: $font-regular;
	font-size: 14px;
	line-height: 22px;
	color:$secondary;
}