// Common pages
.page-content {
    padding: 20px;
    margin-bottom: 27px;
    @include bp(md-screen) {
        padding: 15px;
        margin-bottom: 0;
    }
}

// Modals
.modal-right {
    @extend %modal-right;
}
.modal {
    @extend %common-modal;
}

// Accordion
.common-accordion {
    @extend %common-accordion;
}

// Tabs
.common-nav-tab {
    @extend %common-nav-tab;
}
.common-tab {
    @extend %common-tab;
}

// space classes
.space-30 {
    padding: $space30;
}

.space-10 {
    padding: $space-10;
}

.space-15 {
    padding: $space15;
}
.space-20 {
    padding: $space20;
    @include bp(md-screen) {
        padding: $space15;
    }
}

// Icon Size
.icon-size-22{
    font-size: 22px;
    color: $gray;
}
.top-action-btns {
    text-align: right;
    // @include bp(lg-screen) {
    //     margin-top: $space20;
    // }
    @include bp(md-screen) {
        position: relative;
    }
    ul{
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
        @include bp(md-screen) {
            flex-wrap: nowrap;
            justify-content: inherit;
            overflow-y: hidden;
            overflow-x: auto;
        }
        li{
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
            @include bp(md-screen) {
                width: 100%;
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                @include bp(md-screen) {
                    width: 100%;
                    white-space: nowrap;
                }
                .custom-icons{
                    &.m-r{
                        margin-right: 6px;
                    }
                    &.m-l{
                        margin-left: 6px;
                    }
                    &.icon-bulb{
                        font-size: 21px;
                    }
                    &.icon-upload{
                        font-size: 21px;
                    }
                }
            }
        }
    }
    .label-with-switch{
        display: flex;
        align-items: center;
        label{
            margin-bottom: 0;
            margin-right: 8px;
        }
        .button-switch{
            width: 72px;
            .switch{
                &::before{
                    width: 72px;
                }
                &:checked:after{
                    transform: translate(43px, 0);
                }
            }
        }
    }
    
    .switch-inner-label{
        display: flex;
        align-items: center;
        .button-switch{
            width: 125px;
            .switch{
                &::before{
                    width: 125px;
                }
                &:checked:after{
                    transform: translate(96px, 0);
                }
            }
            .lbl-off{
                right: 13px;
            }
            .lbl-on{
                left: 14px;
            }
        }
    }
    .dropdown{
        @include bp(md-screen) {
            position: static;
        }
        .dropdown-menu{
            display: none;
            min-width: 100%;
            @include bp(md-screen) {
                width: 100%;
                min-width: 100%;
            }
            &.show{
                display: block;
            }
            li{
                margin-right: 0;
                a{
                    justify-content: flex-start;
                }
            }
            .icon-excel{
                color: $success;
            }
            .icon-pdf{
                color: $danger;
            }
        }
    }
}

// Colors
.first-color {
    color: $primary;
}

// Titles
.page-title {
    @extend %page-title;
}
.account-title {
    @extend %account-title;
}

// Boxes
.common-white-box {
    @extend %common-white-box;
}
.common-primary-box {
    @extend %common-primary-box;
}

// Dropdown
.dropdown-menu {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: $border-radius-6;
    border: none;
    padding: 0;
    overflow: hidden;
    z-index: 9;
    @include dropdownEffect;
    .dropdown-item {
        padding: 7px 10px;
        font-size: $text-14;
        color: $gray;
        position: relative;
        &:hover {
            color: $secondary;
        }
        &:focus {
            color: $secondary;
        }
        .custom-icons {
            min-width: 22px;
            font-size: 22px;
            &.m-r{
                margin-right: 4px;
            }
            &.m-l{
                margin-left: 4px;
            }
        }
    }
    &.dropdown-top-position{
        top: 100% !important;
        margin-top: 3px !important;
    }
}
.dropdown-toggle::after {
    content: "\e938";
    font-family: $icons-font-family;
    border: none;
    vertical-align: middle;
    font-size: $text-18;
    line-height: 18px;
}
.dropdown-arrow-remove::after{
    display: none;
}
.box-dropdown {
    @extend %box-dropdown;
}

// List view
.dash-list {
    @extend %dash-list;
}
.date-time {
    margin: 15px 0 0 0;
    font-size: $text-12;
    color: $gray;
    font-weight:$font-regular;
}

// Status label
.status-label {
    @extend %status-label;
}

// Breadcrumbs, Pagination,
.breadcrumb {
    @extend %breadcrumb;
}
.common-pagination {
    @extend %common-pagination;
}

// Tables
.table {
    @extend %table;
}
.common-border  {
    border: 1px solid $common-box-border;
    border-radius: $border-radius-6;
    &.table-responsive {
        margin-bottom: 20px;
        @extend %scroll;
    }
    &.table-responsive:last-child {
        margin-bottom:0;
    }
    &.pagination-remove{
       
        kendo-pager-prev-buttons{
display: none;
        }
        kendo-pager-next-buttons{
            display: none;
        }
        kendo-pager-numeric-buttons{
            display: none;
        }

        // .kendo-pager-prev-buttons{
        //     display: none;
        // }
        // .k-pager-numbers-wrap{
        //     display: none;
        // }
        // .kendo-pager-next-buttons{
        //     display: none;
        // }
    
    }
}
.visible-mobile-nav {
    display: none;
}
.mt-5 {
    @include bp(md-screen) {
        margin-top: 1.8rem !important;
    }
}
.gray-color {
    color: $gray;
}
.status-files {
    @extend %status-files;
}
.valid-msg {
    @extend %valid-msg;
}
.upload-icon {
    @extend %upload-icon;
}
.valid-msg-check {
    @extend %valid-msg-check;
}

// Modal Common
// .modal-dialog {
//     max-width: 575px;
// }
.kendo-modal {
    @extend %kendo-modal;
}
.k-grid {
    @extend %k-grid;
    .k-grid-pager {
        @extend %k-grid-pager;
    }
}
.common-link {
    font-weight: $font-semibold;
}

// Kendo color overwrite
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
    color: $primary;
}

// Kendo Font family overwrite
.k-dropdown, .k-dropdowntree, .k-window, .k-pager-wrap {
    font-family: $font-family-base;
}
.k-pager-info {
    font-weight: $font-semibold;
    color: $gray-pgn;
    font-size: size(14);
}

.table-dropdown {
    .filter-icon {
        display: inline-block;
        color: $black;
        font-size: $text-20;
        line-height: 20px;
        vertical-align: top;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu {
        // left: auto !important;
        // right: 0 !important;
        min-width: 125px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
        border-radius: $border-radius-6;
        border: none;
        padding: 0;
        //margin-right: 45px !important;
        z-index: 9;
        overflow: hidden;
        animation: none;
        margin-right: 20px !important;
        margin-top: -20px !important;
        margin-bottom: -20px !important;
        .dropdown-item {
            padding: 7px 10px;
            font-size: $text-14;
            color: $gray;
            position: relative;
            display: flex;
            align-items: center;
            &:hover {
                color: $secondary;
            }
            &:focus {
                color: $secondary;
            }
            .custom-icons {
                min-width: 26px;
                font-size: $text-20;
            }
            .active-dot{
                width: 8px;
                height: 8px;
                border-radius: $rounded-pill;
                background-color: $success;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            .deactive-dot{
                width: 8px;
                height: 8px;
                border-radius: $rounded-pill;
                background-color: red;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    // &.dropdown-actions-outer{
    //     .dropdown-menu{
    //         animation: none;
    //         margin-right: 20px !important;
    //         margin-top: -20px !important;
    //         margin-bottom: -20px !important;
    //     }
    // }
}
.k-grid-filter-popup{
    .k-filter-menu-container{
        .k-filter-and{
            width: 100%;
        }
    }
    .k-picker .k-input-inner{
        height: 36px;
    }
    .k-textbox{
        height: 38px;
    }
}
.k-grid-header .k-grid-filter.k-state-active, .k-grid-header .k-header-column-menu.k-state-active, .k-grid-header .k-hierarchy-cell .k-icon.k-state-active{
    background-color: $primary;
    border-radius: $border-radius-6;
}
.user-permissions{
    width: 24px;
    height: 24px;
    border-radius: $rounded-pill;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    &.green{
        background-color: $success;
    }
    &.red{
        background-color: $danger;
    }
    &.gray{
        background-color: $gray;
    }
    .icon-check{
        font-size: 10px;
    }
    .icon-close-small{
        font-size: 16px;
    }
}
.permissions-check{
    .k-checkbox[type=checkbox]{
        width: 24px;
        height: 24px;
        border-radius: $rounded-pill;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        &:checked::after{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
        &.green{
            background-color: $success;
            border-color: $success;
            &::after{
                content: "\e939";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 11px;
            }
        }
        &.red{
            background-color: $danger;
            border-color: $danger;
            &::after{
                content: "\e92b";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 16px;
            }
        }
        &.gray{
            background-color: $gray;
            border-color: $gray;
            &::after{
                content: "\e92b";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 16px;
            }
        }
    }
    .k-checkbox:checked[type=checkbox]{
        &.green{
            background-color: $success;
            border-color: $success;
            &::after{
                content: "\e939";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 11px;
            }
        }
        &.red{
            background-color: $danger;
            border-color: $danger;
            &::after{
                content: "\e92b";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 16px;
            }
        }
        &.gray{
            background-color: $gray;
            border-color: $gray;
            &::after{
                content: "\e92b";
                font-family: "icomoon" !important;
                color: #ffffff;
                font-size: 16px;
            }
        }
    }
}
.common-color-table{
    .k-grid{
        .k-grid-header{
            background-color: $tertiary;
            color: $white;
            .k-checkbox[type=checkbox]{
                border-color: $white;
            }
            .k-checkbox:checked[type=checkbox] {
                background-color: $primary;
                border-color: $primary;
            }
        }
        th{
            border-width: 0 0 1px 1px;
            border-color: rgba(255,255,255,0.2);
            border-style: solid;
        }
        td{
            border-width: 0 0 1px 1px;
            border-color: rgba(151, 151, 151, 0.2);
            &:first-child{
                border-left-width:0;
            }
        }
        .k-alt {
            background-color: $grid-alt !important;
        }
        .fw-semibold{
            font-weight: $font-semibold;
        }
    }

}
.inner-action-section{
    position: sticky;
    top: 60px;
    background-color: $gray-body;
    z-index: 10;
    margin: -20px -20px 0 -20px;
    padding: 20px;
    @include bp(md-screen) {
        margin: -15px -15px 0 -15px;
        padding: 15px;
    }
}
.inner-full-action{
    .common-nav-tab {
        @include bp(exl-screen) {
            margin-bottom: 20px;
        }
        @include bp(md-screen) {
            margin-bottom: 15px;
        }
    }
}

// Custom pager
.custom-datapager {
    .k-pager-wrap{
        background-color: $gray-account;
        border: none;
        border: 1px solid $common-box-border;
        border-radius: $border-radius-6;
        padding: 9px 20px;
        font-size: 14px;
        font-weight: 600;
        color: $gray-pgn;
        @include bp(lg-screen) {
            justify-content: center;
        }
        &:focus {
            box-shadow: none;
        }
        .k-pager-nav, .k-pager-numbers {
            &.k-link, .k-link {
                background-color: transparent;
                border-color: transparent;
                font-size: $text-14;
                font-weight: $font-semibold;
                color: $primary;
                min-width: 30px;
                height: 30px;
                &:focus {
                    box-shadow: $box-shadow-0;
                    outline: none;
                }
            }
            &.k-state-selected, .k-state-selected {
                border-radius: $border-radius-2;
                background-color: $primary;
                color: $white;
            }
            .k-icon {
                color: $gray-pgn;
            }
        }
    }
    .k-pager-sizes{
        color: $gray-pgn;
        font-weight: 500;
        font-size: 14px;
        .k-dropdownlist{
            width: 65px;
        }
        .k-picker .k-input-inner{
            height: 30px;
            padding: 3px 0px 3px 10px;
            text-align: center;
        }
        .k-button{
            padding: 3px 10px 3px 3px;
            .k-i-arrow-s::before{
                font-size: 18px;
            }
        }
    }
}
.breakWord{
    word-break: break-all !important;
    word-wrap: break-word !important;
}

// Grid pager bottom fixed
.primary-box-pager-grid{
    .k-grid{
        height: calc(100vh - 284px);
        @include bp(lg-screen) {
            height: calc(100vh - 342px);
        }
        .k-grid-norecords{
          td{
              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: -1;
          }
      }
    }
}
.only-pager-grid{
    .k-grid{
        height: calc(100vh - 191px);
        @include bp(md-screen) {
            height: calc(100vh - 202px);
        }
        .k-grid-norecords{
          td{
              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: -1;
          }
      }
    }
}
.user-role-scroll{
    .k-grid{
        height: calc(100vh - 258px);
        @include bp(md-screen) {
            height: 100%;
        }
    }
}
.create-role-scroll{
    .k-grid{
        height: calc(50vh - 191px);
        @include bp(md-screen) {
            height: calc(5vh - 202px);
        }
        .k-grid-norecords{
          td{
              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: -1;
          }
      }
    }
}
.only-data-grid-height{
    .k-grid{
        max-height: 500px;
    }
}
.only-data-grid-height-sm{
    .k-grid{
        max-height: 315px;
    }
}

// Common Report Info
.common-report-info{
    .report-box{
        background-color: $info;
        // border: 1px solid $primary;
        border-radius: $border-radius-10;
        color: $white;
        padding: size(51) size(35);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: size(10);
        &:last-child{
            margin-bottom: 0;
        }
        h5{
            color: $white;
        }
        h3, li{
            font-size: size(22);
            line-height: size(30);
            color: $white;
            font-weight: $font-semibold;
        }
    }
    &.one-card-report-box{
        .report-box{
            padding: size(207) size(35);
        }
    }
    &.two-card-report-box{
        .report-box{
            padding: size(95) size(35);
        }
    }
    &.three-card-report-box{
        .report-box{
            padding: size(57) size(35);
        }
    }
    &.four-card-report-box{
        .report-box{
            padding: size(37.5) size(35);
        }
    }
    &.five-card-report-box{
        .report-box{
            padding: size(20) size(35);
        }
    }
}

// Inner Page Title
.inner-page-title{
    h1, h2, h3, h4, h5, h6{
        margin-bottom: 0;
        @include bp(md-screen) {
            margin-bottom: size(15);
        }
    }
}

// Breadcrumb
.breadcrumb-sec{
    @include bp(md-screen) {
        margin-bottom: size(15);
    }
    ul{
        display: flex;
        align-items: center;
        .breadcrumb-link{
            font-size: size(16);
            line-height: size(22);
            font-weight: $font-medium;
            color: $secondary;
            display: flex;
            align-items: center;
            &::after{
                content: "\e92f";
                font-family: "icomoon";
                font-size: size(16);
                color: $primary;
                margin:  0 size(3);
            }
            &.active{
                &::after{
                    display: none;
                }
            }
        }
    }
}

// Loader Spinner
.loader-spinner-section{
    .loading-text {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        img{
            width: 90px;
        }
    }
}

.common-primary-box-spinner{
    position: relative;
    height: 100%;
    .ngx-spinner-overlay{
        background-color: rgba(0, 0, 0, 0.6) !important;
        z-index: 99 !important;
    }

    img{
        width: 90px;
        .vob-info-box &{
            width: 30px;    
        }
        .ex-vob-info-box &{
            width: 30px;    
        }
    }
    .vob-info-box &{
        padding: 20px 15px;
    }
    .ex-vob-info-box &{
        padding: 20px 15px;
    }
}


.k-notification-error{
    background-color: #f6dfdf;;
    border-color: #f6dfdf;
    color: $danger;
    border-radius: $border-radius-6;
    font-size: size(14) !important;
    font-weight: $font-medium !important;
    font-family: $font-family-base;
    border-left: 5px solid $danger;
    box-shadow: 0px 2px 12px rgba(255, 37, 37, 0.3) !important;
    border-top: none;
    border-right: none;
    border-bottom: none;
    margin-right: 8px;
}
.k-notification-success{
    background-color: #d7ffe9;
    border-color: #d7ffe9;
    color: $success;
    border-radius: $border-radius-6;
    font-size: size(14) !important;
    font-weight: $font-medium !important;
    font-family: $font-family-base;
    border-left: 5px solid $success;
    box-shadow: 0px 2px 12px rgba(0, 176, 80, 0.3) !important;
    border-top: none;
    border-right: none;
    border-bottom: none;
    margin-right: 8px;
}
.k-overlay{
  background-color: rgba(0, 0, 0, 0.5);
  opacity: inherit;
}
.kendo-customCheckbox{
    margin-left: 26px;
    padding: 10px 0;
    .k-checkbox-label{
        margin-left: 12px;
    }
}

.settings-section{
    .button-switch.setting-switch{
        width: 65px;
        flex: 0 0 auto;
        .lbl-on{
            left: 10px;
        }
        .switch{
            &::before{
                width: 65px;
            }
            &:checked:after{
                left: 0;
                transform: translate(35px, 0);
            }
        }
    }
    .setting-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: size(15);
        margin-bottom: size(20);
        &:last-child{
            margin-bottom: 0;
        }
    }
    .note-text-box{
        background-color: rgba($danger, 0.1);
        padding: size(12) size(20);
        margin-bottom: size(15);
        border-radius: $border-radius-6;
        p{
            color: $danger;
            margin-bottom: 0;
            span{
                color: $secondary;
                font-weight: $font-semibold;
            }
        }
    }
}

.note-title-info-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: size(10);
    gap: 10px;
    @include bp(md-screen) {
        width: 100%;
        flex-direction: column;
    }
    h6{
        margin-bottom: 0;
    }
    .form-group{
        @include bp(md-screen) {
            width: 100%;
        }
    }
    .info-title{
        @include bp(md-screen) {
            width: 100%;
        } 
    }
    .info-action{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        @include bp(md-screen) {
            width: 100%;
        }
        .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            .custom-icons{
                margin-right: size(6);
                font-size: size(20);
            }
            @include bp(md-screen) {
                width: 100%;
            }
        }
        .k-picker{
            min-width: 150px;
            @include bp(md-screen) {
                min-width: 100%;
            }
        }
        .action-box{
            display: flex;
            gap: 15px;
            @include bp(md-screen) {
                width: 100%;
                flex-direction: column;
            }
        }
    }
}
.tenure-goal-grid{
    .header-title-center{
        .k-cell-inner{
            .header-title-grid{
                span{
                    display: block;
                }
            }
        }
    }
}
.task-detail-modal{
    .task-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .high{
            font-size: size(14);
            color: $danger;
        }
        .general{
            font-size: size(14);
            color: $warning;
        }
        .low{
            font-size: size(14);
            color: $success;
        }
    }
}
.grid-ui{
    .k-grid {
        .label-with-tooltip{
            justify-content: center;
        }
        .grid-label-btn{
            .status-label-btn{
                &.round-label{
                    width: 30px;
                    height: 30px;
                    border-radius: $rounded-pill;
                    min-width: auto;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.bg-btn-blue{
                    background-color: $info;
                }
                &.bg-btn-yellow{
                    background-color: $warning;
                }
            }
        }
    }
}
.k-dialog-wrapper.kendo-modal.modal-ovarlay-open{
    z-index: 999999;
}
.modal-note-height-fixed{
    height: 420px;
    overflow: auto;
}
.facility-grid-height{
    .k-grid{
        height: 400px;
        overflow: auto;
    }
}
.facility-grid-tab-height{
    .k-grid{
        height: 337px;
        overflow: auto;
    }
}
.box-comman-height-fixed{
    height: 340px;
    overflow: auto;
}
.case-view-info-field{
    .form-group{
        span{
            min-height: 20px;
            display: block;
        }
    }
}
.auth-precet{
    background-color: #FFD9D9 !important;
}