.facility-accordion{
    height: calc(100vh - 256px);
    overflow-y: auto;
    margin-bottom: 15px;
    @include bp(lg-screen) {
        height: calc(100vh - 314px);
    }
    @include bp(md-screen) {
        height: calc(100vh - 329px);
    }
    .accordion-item {
        margin-bottom: 15px;
        background-color: $white;
        border: 1px solid $gray-tabs !important;
        border-radius: $border-radius-6;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .accordion-header {
        border-radius: $border-radius-6;
        background-color: $primary-light;
        border-color: $primary-light;
        padding: 12px 80px 12px 15px;
        position: relative;
        cursor: pointer;
        h3 {
            font-size: $text-14;
            font-weight: $font-semibold;
            margin: 0;
            line-height: 22px;
        }
        &:not(.collapsed)::after {
            content: "\e938";
            font-family: $icons-font-family;
        }
        &::after {
            content: "\e92f";
            font-family: $icons-font-family;
            color: $white;
            font-size: 18px;
            background-color: $tertiary;
            border-radius: $border-radius-4;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            @include transition(all 0.3s ease-in-out);
            @include bp(lg-screen) {
                top: 20px;
                transform: inherit;
            }
        }
        &:hover::after{
            background-color: darken($tertiary, 9);
        }
    }
    .accordion-body {
        padding: 15px 15px 0 15px;
        .common-primary-box{
            height: calc(100% - 15px);
            margin-bottom: 15px;
            .primary-body{
                .space-15{
                    padding: 15px 15px 0 15px;
                }
            }
            .facilities-information-box{
                margin-bottom: 15px;
                margin-top: 0;
            }
        }
    }
    .facility-view-icon{
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        @include bp(lg-screen) {
            top: 20px;
            transform: inherit;
        }
        a{
            font-size: 22px;
            background-color: $tertiary;
            line-height: 24px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-4;
            color: $white;
            &:hover{
                background-color: darken($tertiary, 9);
            }
        }
    }
}
.facilities-img-info{
    display: flex;
    align-items: center;
    .img-box{
        flex: 0 0 auto;
        margin-right: 15px;
        img{
            width: 47px;
            height: 47px;
            object-fit: cover;
            border-radius: $border-radius-6;
        }
    }
    .facilities-information-box{
        margin-top: 0;
    }
}
.facilities-information-box{
    span{
        color: $gray;
        font-size: $text-14;
        line-height: 20px;
        display: block;
        font-weight: $font-medium;
    }
    h6{
        margin-bottom: 4px;
        font-weight: $font-regular;
    }
    @include bp(lg-screen) {
        margin-top: 15px;
    }
}
.add-remove-row-sec{
    .add-remove-row{
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        gap: 15px;
        @include bp(md-screen) {
            flex-wrap: wrap;
        }
        .form-label{
            display: none;
            @include bp(md-screen) {
                display: block;
            }
        }
        &:first-child{
            .form-label{
                display: block;
            }
        }
    }
    .add-remove-inner{
      display: flex;
      align-items: flex-end;
      flex-wrap: nowrap;
      gap: 15px;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      @include bp(md-screen) {
        flex-wrap: wrap;
      }
    }
    .add-remove-clone{
      width: 100%;
    }
    .primary-group{
        width: 30%;
        @include bp(md-screen) {
            width: 100%;
        }
    }
    .description-group{
        width: 70%;
        @include bp(md-screen) {
            width: 100%;
        }
    }
    .remove-ic{
      @include bp(md-screen) {
        width: 100%;
      }
        .icon-trash{
          color: $count-status;
          background-color: #FFF1F1;
          width: 46px;
          height: 46px;
          border-radius: $border-radius-6;
          flex: 0 0 auto;
          font-size: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @include bp(md-screen) {
              width: 100%;
          }
        }
        .icon-plus{
          color: $primary;
          background-color: $primary-light;
          width: 46px;
          height: 46px;
          border-radius: $border-radius-6;
          flex: 0 0 auto;
          font-size: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 22px;
          @include bp(md-screen) {
              width: 100%;
          }
        }
    }
}
