header {
    padding: 7px 20px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.03);
    background-color: $white;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;

    @include bp(md-screen) {
        height: 60px;
    }

    .navbar {
        padding: 0;
    }

    .header-main-search {
        display: flex;
        align-items: center;
    }

    .unread-messages-alert {
        animation: blinker 1s linear infinite;
    }

    .header-search-box {
        position: relative;

        .k-input-inner {
            padding: 0;
            font-size: 14px;
            color: $secondary;
            @include transition(all 0.3s ease-in-out);
        }

        .k-input {
            height: 40px;
            border-radius: $border-radius;
            border: none;
            @include transition(all 0.3s ease-in-out);
            padding: 0;
            width: 0;
        }

        .k-focus {
            border-color: $primary !important;
        }

        .k-clear-value {
            width: calc(0px + 0.75rem);
        }

        .search-ic-box {
            margin-right: 45px;

            .search-ic {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                color: $secondary;
                font-size: 20px;

                &:hover {
                    color: $primary;
                }
            }

            .close-ic {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                color: $secondary;
                font-size: 20px;
                visibility: hidden;
                opacity: 0;

                &:hover {
                    color: $primary;
                }
            }
        }

        &.search-open {
            @include bp(md-screen) {
                position: absolute;
                z-index: 999;
            }

            .k-input {
                padding: 6px 15px 6px 45px;
                border: 1px solid $gray-fields;
                width: 290px;

                @include bp(md-screen) {
                    width: calc(100vw - 28px);
                    height: 46px;
                }
            }

            .search-ic-box {
                .search-ic {
                    color: $gray;
                }

                &:hover {
                    .search-ic {
                        visibility: hidden;
                        opacity: 0;
                    }

                    .close-ic {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        &.patient-search-open {
            margin-left: size(14);

            @include bp(md-screen) {
                position: absolute;
                z-index: 999;
                margin-left: 0;
            }

            .k-input {
                padding: 6px 15px 6px 45px;
                border: 1px solid $gray-fields;
                width: 290px;

                @include bp(md-screen) {
                    width: calc(100vw - 28px);
                    height: 46px;
                }
            }

            .search-ic-box {
                .search-ic {
                    color: $gray;
                }

                &:hover {
                    .search-ic {
                        visibility: hidden;
                        opacity: 0;
                    }

                    .close-ic {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        &.patient-search-box {
            .icon-user {
                color: $danger;
            }
        }
    }

    .profile-img {
        display: inline-block;
        border-radius: $border-radius-50;

        img {
            border-radius: $border-radius-50;
            object-fit: cover;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            padding: 12px 20px;
            color: $secondary;
            display: flex;
            align-items: center;

            &:hover,
            &.active-link {
                background-color: $primary-light;
                color: $tertiary;
            }

            &:focus {
                background-color: $primary-light;
                color: $tertiary;
            }

            .custom-icons {
                font-size: 20px;
                min-width: 40px;
                vertical-align: top;

                &.icon-lock {
                    font-size: 28px;
                    margin-left: -6px;
                    min-width: 45px;
                }
            }
        }
    }

    .navbar-expand {
        .navbar-nav {
            .profile-dropdown {
                @include bp(md-screen) {
                    position: static;
                }

                .nav-link {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .dropdown-menu {
                    left: auto;
                    right: 0;
                    background-color: $white;
                    min-width: 289px;
                    border-radius: $border-radius-8;
                    margin-top: 5px;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
                    padding: 0;
                    border: none;
                    overflow: hidden;

                    @include bp(md-screen) {
                        top: auto;
                        bottom: 56px;
                        margin-top: 0;
                        min-width: 100%;
                        width: 100%;
                        border-radius: $border-radius-8 $border-radius-8 0 0;
                        box-shadow: 0 -10px 10px 0px rgba(0, 0, 0, 0.10);
                    }

                    li {
                        margin-top: 1px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                .dropdown-toggle {
                    &::after {
                        content: "\e938";
                        border: none;
                        font-family: "icomoon" !important;
                        vertical-align: middle;
                        font-size: $text-16;

                        @include bp(md-screen) {
                            display: none;
                        }
                    }
                }

                .user-name {
                    padding: 12px $space20;
                    margin: 0;
                    font-weight: $font-semibold;
                    color: $tertiary;
                    background-color: $primary-light;
                    border-radius: $border-radius-8;
                    font-size: $text-14;
                    line-height: 22px;
                }
            }

            .nav-link {
                padding-left: 12px;
                padding-right: 12px;
                font-size: $text-20;
                color: $secondary;
                position: relative;

                // @include bp(md-screen) {
                //     padding-left: 5px;
                //     padding-right: 5px;
                // }
                &:hover {
                    color: $primary;
                }

                &:focus {
                    color: $primary;
                }
            }

            .nav-item:last-child {
                .nav-link {
                    padding-right: 0;
                }
            }

            .nav-status {
                width: 14px;
                height: 14px;
                background-color: $count-status;
                border: 1px solid $white;
                position: absolute;
                right: 9px;
                top: 11px;
                border-radius: $border-radius-50;
            }
        }
    }
}


.notifications-row {
    &.message-box {
        .no-read {
            background-color: $primary-light;
            margin: -15px;
            padding: 15px;
        }
    }

    .notifications-cell {
        padding: 15px;
        border-top: 1px solid $gray-account;
        cursor: pointer;

        &:first-child {
            border-top: 0;
        }

        h5 {
            font-weight: $font-medium;
            margin: 0 0 6px 0;
            font-size: 14px;
            line-height: 22px;
        }

        .dot-ic {
            width: 10px;
            height: 10px;
            border-radius: $rounded-pill;

            &.high {
                background-color: $danger;
            }

            &.general {
                background-color: $warning;
            }

            &.low {
                background-color: $success;
            }
        }

        p {
            font-size: 14px;
            line-height: 22px;
            margin: 0 0 6px 0;
            color: $gray;
        }

        span {
            font-size: 12px;
            line-height: 16px;
            color: $gray;
        }

        .title-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

// iPad View Media Queries
@include bp(xl-screen) {
    .nav-toggle {
        overflow: hidden;
    }
}

// Mobile View Media Queries
@include bp(md-screen) {
    .visible-mobile-nav {
        display: block;
    }

    header {
        .navbar-expand.justify-content-end {
            position: fixed;
            left: 0;
            bottom: 0;
            background-color: $gray-tabs;
            justify-content: space-between !important;
            width: 100%;
            z-index: 100;
            padding: 5px 15px;
        }

        .header-search {
            &.hidden-search {
                .header-mobile-search {
                    width: 0px;
                    visibility: hidden;
                }
            }

            .header-mobile-search {
                width: 100%;
                visibility: visible;
                background-color: $gray-tabs;
                position: fixed;
                left: 0;
                bottom: 57px;
                padding: 5px 15px;
            }
        }

        .common-navbar {
            width: 58%;
        }

        .navbar-expand {
            .navbar-nav {
                justify-content: space-between;
            }
        }
    }
}

.usr-circle {
    width: 46px;
    height: 46px;
    border-radius: $border-radius-50;
    object-fit: cover;
    border: 3px solid;
}

.dropdown-menu li {
    position: relative;
}

.dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 50% !important;
    top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
    display: block;
}

.dot-15 {
    margin: 0 25px 0 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

.dot-10 {
    margin: 0 10px 0 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.minusSign {
    text-align: center;
    line-height: 0.8em;
    color: white;
}