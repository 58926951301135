a{
    color: $primary;
    text-decoration: none;
    outline: none;
    &:hover {
        color: darken($primary, 9);
        box-shadow: none;
        outline: none;
        text-decoration: none;
    }
    &:focus {
        color: darken($primary, 9);
        box-shadow: none;
        outline: none;
        text-decoration: none;
    }
}
  
a, button, .btn {
    @include transition(all 0.3s ease-in-out);
}
.btn-block {
    @extend %btn-block;
}
.btn {
    font-size: $text-14;
    box-shadow: none !important;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
    &.btn-sm {
        font-size: $text-14;
        padding: 9px 16px;
        border-radius: $border-radius-4;
        font-weight: $font-semibold;
        // @include bp(lg-screen) {
        //     min-width: 90px;
        //     padding: 6px 10px;
        // }
        .custom-icons {
            font-size: $text-18;
            //vertical-align: top;
            @include bp(lg-screen) {
                font-size: $text-16;
                vertical-align: middle;
            }
        }
    }
    &.btn-md {
        border-radius: $border-radius-6;
        font-size: $text-16;
        padding: 10px 16px;
        font-weight: $font-medium;
        min-width: 130px;
    }
    &.btn-lg {
        font-size: $text-18;
        font-weight: $font-semibold;
        padding: size(18) size(15) size(18);
        border-radius: $border-radius-6;
        @include bp(md-screen) {
            font-size: $text-16;
            padding: $space-10 $space15;
        }
    }
    &.btn-primary {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        &:hover {
            background-color: darken($primary, 9);
            border-color: darken($primary, 9);
            color: $white;
        }
        &.filter-active{
                background-color: darken($primary, 9);
                border-color: darken($primary, 9);
                color: $white;
            
        }
        &:focus {
            background-color: darken($primary, 9);
            border-color: darken($primary, 9);
            color: $white;
        }
    }
    &.btn-secondary {
        background-color: $tertiary;
        border-color: $tertiary;
        color: $white;
        &:hover {
            background-color: darken($tertiary, 9);
            border-color: darken($tertiary, 9);
            color: $white;
        }
        &:focus {
            background-color: darken($tertiary, 9);
            border-color: darken($tertiary, 9);
            color: $white;
        }
        .custom-icons {
            font-size: $text-18;
            vertical-align: middle;
        }
    }
    &.btn-white {
        background-color: $white;
        border-color: $white;
        color: $tertiary;
        font-weight: $font-medium;
        &:hover {
            background-color: darken($white, 9);
            border-color: darken($white, 9);
            color: $tertiary;
        }
        &:focus {
            background-color: darken($white, 9);
            border-color: darken($white, 9);
            color: $tertiary;
        }
        .custom-icons {
            font-size: $text-18;
            vertical-align: middle;
        }
    }
    &.btn-cancel {
        background-color: $gray-account;
        border-color: $gray-account;
        color: $gray;
        &:hover {
            background-color: darken($gray-account, 9);
            border-color: darken($gray-account, 9);
            color: darken($gray, 9);
        }
        &:focus {
            background-color: darken($gray-account, 9);
            border-color: darken($gray-account, 9);
            color: darken($gray, 9);
        }
    }
    &.btn-primary-outline {
        background-color: $white;
        border-color: $primary;
        color: $primary;
        &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
        &:focus {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    &.btn-gray-outline {
        background-color: $white;
        border-color: $gray;
        color: $gray;
        &:hover {
            background-color: $gray;
            border-color: $gray;
            color: $white;
        }
        &:focus {
            background-color: $gray;
            border-color: $gray;
            color: $white;
        }
    }
}
.k-button{
    font-size: 14px;
    padding: 8px 16px;
    border-radius: $border-radius-6;
    font-weight: 600;
    box-shadow: none !important;
    background-color: $gray-account;
    border-color: $gray-account;
    color: $gray;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
    &.k-button-solid-primary{
        background-color: $primary;
        border-color: $primary;
        color: $white;
        border-radius: $border-radius-6 !important;
        &:hover, &:focus {
            background-color: darken($primary, 9);
            border-color: darken($primary, 9);
            color: $white;
        }
    }
    &.k-button-solid-base{
        background-color: $gray-account;
        border-color: $gray-account;
        color: $gray;
        border-radius: $border-radius-6 !important;
        &:hover, &:focus {
            background-color: darken($gray-account, 9);
            border-color: darken($gray-account, 9);
            color: $gray;
        }
    }
    &.k-input-button{
        background-color: transparent;
        border-color: transparent;
        &:hover, &:focus {
            background-color: transparent;
            border-color: transparent;
        }
    }
}
.grid-btn-group{
    display: flex;
    align-items: center;
    .grid-btn{
        background-color: transparent;
        border: none;
        border-radius: 0 !important;
        font-size: 22px;
        padding: 4px;
        color: $gray;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover, &:focus{
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
        }
        &.grid-primary-ic:hover{
            color: $primary;
        }
        &.grid-danger-ic:hover{
            color: $danger;
        }
        &.grid-success-ic:hover{
            color: $success;
        }
        .icon-pencil, .icon-wallet{
            font-size: 20px;
        }
        .k-i-play, .k-i-stop {
            font-size: 12px;
        }
    }
}


