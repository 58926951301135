.tofacility-unread {
    font-weight: bold;
    float: left;
    width: 100%;
}

.tofacility-read {
    font-weight: normal;
    float: left;
    width: 100%;
}

.fromfacility-unread {
    font-weight: bold;
    float: right;
    width: 100%;
}

.fromfacility-read {
    font-weight: normal;
    float: right;
    width: 100%;
}

.facilityMessageHeader {
    font-size: smaller;
    font-style: italic;
}

.add-new-message {
    .textarea-message-height {
        height: 150px;
        @include bp(lg-screen) {
            height: 70px;
        }
    }
}

.kendo-modal.danger {
    .k-dialog {
        min-height: 300px;
    }

    .k-dialog-titlebar {
        background-color: red;
    }
}