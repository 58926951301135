.dashboard-section{
    .info-box-scroll{
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 708px;
        height: 100%;
        .announcements-box{
            border-bottom: 1px solid $border-color;
            padding-bottom: 15px;
            margin-bottom: 15px;
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
}
.executive-dashboard-section{
    .executive-cards{
        .vob-info-box{
            &:hover{
                background-color: rgb(217, 237, 246);
                color: rgb(98, 203, 230);
                h6,h2{
                    color: rgb(98, 203, 230);
                }
            }
        }
    }
    .vob-info-box{
        padding:0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .vob-info-box h2{
        @media (max-width: 1440px) {
            font-size: 26px;
        }
        @include bp(md-screen) {
            font-size: 20px;
        }
    }
    .common-white-box{
        margin-bottom: size(24);
        height: calc(100% - 24px);
        padding: 20px;
    }
    .executive-dropdownlist{
        display: flex;
        flex-wrap: wrap;
        .form-group{
            min-width: 220px;
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
            @include bp(md-screen) {
                min-width: 100%;
                margin-right: 0;
            }
        }
    }
    .chart-dropdownlist-box{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: flex-end;
        margin-bottom: 0;
        padding:20px;
        padding-bottom: 0;
        .k-picker{
            min-width: 180px;
            width: auto;
            @media (max-width: 1700px) {
                min-width: auto;
                width: 155px;
            }
            @include bp(exl-screen) {
                min-width: 180px;
                width: auto;
            }
            @include bp(md-screen) {
                min-width: 100%;
                width: 100%;
            }
        }
    }
    .chart-with-info{
        display: flex;
        @include bp(md-screen) {
            display: block;
        }
        .utilization-info{
            margin-right: 20px;
            width: 250px;
            @include bp(md-screen) {
                width: 100%;
            }
        }
        .utilization-box{
            background-color: $info;
            text-align: center;
            border-radius: 10px;
            padding: 20px 15px;
            margin-bottom: 20px;
            h6{
                color: $white;
                margin-bottom: 8px;
            }
            h4{
                font-weight: $font-semibold;
                color: $white;
            }
        }
    }
    .title-with-viewicon{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        .custom-icons{
            font-size: 20px;
            color: $gray;
            cursor: pointer;
            @include transition(all 0.3s ease-in-out);
            &:hover{
                color: $primary;
            }
        }
    }
    .executive-report-box{
        border: 1px solid $primary;
        border-radius: $border-radius-10;
        padding: size(60) size(16);
        text-align: center;
        background-color: $primary;
        cursor: pointer;
        margin-bottom: size(24);
        @include transition(all 0.3s ease-in-out);
        h4{
            font-size: size(18);
            color: $white;
        }
        &:hover{
            background-color: rgb(217, 237, 246);
            border-color: rgb(217, 237, 246);
            h4{
                color: $primary;
            }
        }
    }
    .chart-box{
        padding: 20px;
    }
    .chart-with-info{
        padding: 20px;
    }
    .common-primary-box-with-dropdown{
        .chart-box{
            height: calc(100% - 120px);
        }
        .chart-not-found-msg{
            height: calc(100% - 120px);
        }
    }
}
.prefix-modal-table{
    .prefix-table{
        .text-gray{
            color: $gray;
        }
        .table-title{
            background-color: $primary-light;
        }
    }
    .prefix-table-fixed{
        width: 100%;
        max-height: 620px;
        overflow-y: auto;
        thead{
            position: sticky;
            top: 0;
            z-index: 9;
        }
    }
    .overall-dropdown{
        max-width: 220px;
    }
}