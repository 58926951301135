// Scroll
%scroll {
    scrollbar-face-color: $primary;
    scrollbar-track-color: $gray-account;
    scrollbar-color: $primary $gray-account;
    scrollbar-width: thin;
    scrollbar-width: thin;
    scrollbar-color: $primary $gray-account;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: $gray-account;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: $primary;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: $primary;
    }
}

// Titles Start
%account-title {
    margin: 0 0 35px;

    @include bp(md-screen) {
        margin: 0 0 20px;
    }

    p {
        font-size: $text-16;
        color: $gray;
        margin: 20px 0 30px;
        text-align: center;

        @include bp(md-screen) {
            font-size: $text-14;
            margin: 10px 0 20px;
        }
    }
}

%page-title {
    h1 {
        color: $primary;
    }

    @include bp(md-screen) {
        padding: 10px 0 10px 60px;
    }
}

// =======MODAL STYLE Start=======

// Modal Right
%modal-right {
    &.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 442px;
            height: 100%;
            -webkit-transform: translate3d(0%, 0, 0);
            -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);

            @include bp(md-screen) {
                width: 100%;
            }
        }

        .modal-content {
            border-radius: 0;
            border: none;
        }

        .modal-header {
            border-radius: 0px;
            padding: 15px;

            .modal-title {
                .custom-icons {
                    font-size: $text-20;
                }
            }
        }

        .btn-close {
            background-image: none;
            opacity: 1;
            position: absolute;
            right: 15px;
            width: auto;
            height: auto;

            &::before {
                font-family: $icons-font-family;
                color: $white;
                font-size: $text-18;
                content: "\e92a";
            }
        }

        .modal-footer {
            background-color: $gray-account;
            padding: 12px 15px;
        }

        .modal-content {
            height: 100%;
            overflow-y: auto;
        }

        .modal-body {
            padding: 0px;
            height: -moz-calc(100vh - (55px + 46px));
            height: -webkit-calc(100vh - (55px + 46px));
            height: calc(100vh - (55px + 46px));
            overflow: auto;
            @extend %scroll;

            .common-nav-tab {
                margin-bottom: 0px;
            }

            // .tab-content{
            //     padding: 0 15px 0px 15px;
            // }
            .add-task-btn {
                padding: 0 15px;
            }

            .modal-inner-tabs {
                padding: 15px;
                background-color: $white;
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }

        &.fade {
            .modal-dialog {
                right: -442px;
                -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
                -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
        }

        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }
}

// ------Common Modal-----
%common-modal {
    .modal-content {
        border-radius: $border-radius-12;
        border: none;
    }

    .modal-header {
        border-radius: $border-radius-10 $border-radius-10 0 0;
        background-color: $primary;
        color: $white;
        padding: 8px 20px;

        .btn-close {
            background-image: none;
            opacity: 1;
            position: absolute;
            right: 15px;
            width: auto;
            height: auto;

            &::before {
                font-family: $icons-font-family;
                color: $white;
                font-size: $text-18;
                content: "\e92a";
            }
        }

        .modal-title {
            font-size: $text-16;
            font-weight: $font-semibold;
            color: $white;
        }
    }

    .modal-footer {
        border-radius: 0 0 $border-radius-10 $border-radius-10;
        padding: 0px 20px 25px;
        border-top: none;
    }

    .modal-body {
        padding: 13px 20px;
    }
}

.modal-backdrop.show {
    opacity: 0.59;
}

.modal-backdrop {
    background-color: rgba(45, 45, 45, 1);
}

// ==========Common TAB==========
%common-nav-tab {
    background-color: $white;
    border: 1px solid $gray-tabs;
    border-radius: $border-radius-6;
    display: inline-block;
    padding: 2px;

    @include bp(lg-screen) {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
    }

    @include bp(lg-screen) {
        margin-bottom: $space20;
    }

    @include bp(md-screen) {
        margin-bottom: $space15;
    }

    &.mr-btm {
        margin-bottom: 20px;

        @include bp(md-screen) {
            margin-bottom: 15px;
        }
    }

    .nav-item {
        display: inline-block;
        vertical-align: text-bottom;

        @include bp(lg-screen) {
            width: 100%;
            white-space: nowrap;
        }

        .nav-link {
            display: block;
            text-align: center;
            color: $gray;
            padding: 6px 25px;
            font-weight: 500;
            border-radius: $border-radius-6;
            border-color: transparent;
            margin-bottom: 0;

            @include bp(md-screen) {
                padding: 7px 15px;
            }
        }

        .active.nav-link {
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }

    &.inner-nav-tav {
        background-color: $light-sky;
        border: none;
        padding: 0;
        border-radius: $border-radius-12 $border-radius-12 0 0;
        width: 100%;

        @include bp(lg-screen) {
            margin-bottom: 0;
        }

        @include bp(md-screen) {
            margin-bottom: 0;
        }

        .nav-item {
            .nav-link {
                color: $secondary;
                padding: 10px 25px;
                border: none;
                border-radius: $border-radius-12 $border-radius-12 0 0;

                &.active {
                    color: $white;
                }
            }
        }
    }

    &.inner-square-nav-tav {
        background-color: $white;
        border-bottom: 1px solid $gray-tabs;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        padding: 0;
        border-radius: 0;
        width: 100%;

        @include bp(lg-screen) {
            margin-bottom: 0;
        }

        @include bp(md-screen) {
            margin-bottom: 0;
        }

        .nav-item {
            .nav-link {
                padding: 10px 25px;
                border: none;
                border-radius: 0;
                margin-bottom: -1px;
            }
        }

        &.mr-btm {
            margin-bottom: 20px;

            @include bp(md-screen) {
                margin-bottom: 15px;
            }
        }
    }
}

%common-tab {
    background-color: $white;
    border: 1px solid $gray-tabs;
    border-radius: $border-radius-6;
    display: inline-block;
    padding: 2px;
    margin-bottom: 20px;

    .common-tab-cell {
        display: inline-block;
        position: relative;
    }

    .common-tab-link {
        display: inline-block;
        text-align: center;
        color: $gray;
        padding: 7px 35px;
        font-weight: $font-medium;
        border-radius: $border-radius-6;

        @include bp(lg-screen) {
            padding: 7px 18px;
        }
    }

    .active {
        .common-tab-link {
            background-color: $primary;
            color: $white;
        }
    }
}

// =========Common accordion==========

%common-accordion {
    .accordion-item {
        margin-bottom: 15px;
        background-color: $white;
        border: 1px solid $gray-tabs;
        border-radius: $border-radius-6;
    }

    .accordion-header {
        border-radius: $border-radius-6;
        background-color: $primary-light;
        border-color: $primary-light;
        padding: 12px 60px 12px 20px;
        position: relative;
        cursor: pointer;

        h3 {
            font-size: $text-14;
            font-weight: $font-semibold;
            margin: 0;
            line-height: 22px;
        }

        &:not(.collapsed)::after {
            content: "\e938";
            font-family: $icons-font-family;
        }

        &::after {
            content: "\e92f";
            font-family: $icons-font-family;
            color: $white;
            font-size: 18px;
            background-color: $tertiary;
            border-radius: $border-radius-4;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include transition(all 0.3s ease-in-out);
        }

        &:hover::after {
            background-color: darken($tertiary, 9);
        }
    }

    .accordion-body {
        padding: $space15;
    }

    .accordion-actions {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: size(15);

        .dropdownlist-box {
            min-width: 200px;

            @include bp(md-screen) {
                min-width: 100%;
            }
        }
    }
}

// ====== Common boxes

%common-white-box {
    background-color: $white;
    border: 1px solid $box-gray;
    border-radius: $border-radius-10;
    padding: 15px;
    overflow: hidden;

    .common-box-bottom {
        border-radius: 0 0 7px 7px;
        background-color: $box-bottom;
        border: none;
        min-height: 38px;
    }

    h3 {
        font-size: $text-16;
        font-weight: $font-semibold;
        line-height: 24px;
    }

    .title-with-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: size(15);
    }
}

%common-primary-box {
    background-color: $white;
    border: 1px solid $common-box-border;
    border-radius: $border-radius-10;
    overflow: hidden;
    .primary-header.priority {
        background-color: goldenrod;
    }

    .primary-header {
        border-radius: $border-radius-6 $border-radius-6 0px 0px;
        background-color: $tertiary;
        color: $white;
        padding: 7px 20px;
        position: relative;
        min-height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .executive-dashboard-section &{
            justify-content: center;
            &.badge-right{
                padding-right: 30px;
                .badge{
                    position: absolute;
                    top: 16px;
                    right: 15px;

                }
            }
        }

        h3 {
            font-size: $text-16;
            font-weight: $font-medium;
            margin: 0;
            line-height: 24px;
            color: $white;

            .custom-icons {
                margin-right: 10px;
            }

            &.title-with-icon {
                display: flex;
                align-items: center;
            }
        }
    }

    .primary-body {
        .space {
            padding: 20px;

            @include bp(md-screen) {
                padding: 15px;
            }
        }

        h3 {
            font-weight: $font-medium;
            margin-bottom: $space15;
            font-size: $text-14;
            line-height: 22px;
        }
    }

    &.height-100 {
        height: calc(100% - 24px);
        margin-bottom: 24px;
    }

    &.height-auto {
        height: 100%;
        margin-bottom: 0;
    }

    .box-button {
        .btn {
            display: flex;
            align-items: center;
        }
    }

    &.light-box {
        .primary-header {
            background-color: $primary-light;

            h3 {
                margin-bottom: 0;
                color: $secondary;
            }
        }
    }
}

%circle-120 {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    //overflow: hidden;
    background-color: $white;
    text-align: center;
    display: inline-block;

    .icon-picture {
        font-size: 42px;
        line-height: 120px;
        color: $gray;
    }

    img {
        border-radius: $border-radius-50;
    }
}

// Drop downs
%box-dropdown {
    border: none;
    outline: none;
    color: $primary;

    &:hover {
        border: none;
        box-shadow: none;
        outline: none;
    }

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
}

// List view
%dash-list {
    color: $primary;

    li {
        margin-bottom: 10px;
        font-weight: $font-medium;
    }

    li:last-child {
        margin-bottom: 0;
    }
}

// Status labels
%status-label {
    border-radius: $border-radius-4;
    font-size: size(14);
    line-height: size(18);
    text-align: center;
    color: $white;
    font-weight: $font-medium;
    padding: size(4) size(10);
    min-width: 86px;
    display: inline-block;
    cursor: pointer;

    &.bg-btn-gray {
        background-color: $light;
        color: $white;

        &:hover {
            background-color: darken($light, 9);
        }

        &[disabled],
        &:disabled {
            opacity: 0.5;
        }
    }

    &.bg-btn-warning {
        background-color: $yellow;
        color: $black;

        &:hover {
            background-color: darken($yellow, 9);
        }
    }

    &.bg-btn-danger {
        background-color: $danger;
        color: $white;

        &:hover {
            background-color: darken($danger, 9);
        }
    }

    &.bg-btn-success {
        background-color: $success;
        color: $white;

        &:hover {
            background-color: darken($success, 9);
        }
    }

    &.bg-btn-info {
        background-color: $info;
        color: $white;

        &:hover {
            background-color: darken($info, 9);
        }
    }

    &.outline-btn-gray {
        background-color: $white;
        border: 1px solid $gray;
        color: $gray;

        &:hover {
            background-color: darken($white, 9);
        }
    }
    &.bg-btn-darkgreen{
        background-color: $darkgreen;
        border: 1px solid $darkgreen;
        color: $white;
        &:hover {
            background-color: darken($darkgreen, 9);
        }
    }
    &.outline-btn-white{
        background-color: $white;
        border: 1px solid $gray;
        color: $black;

        &:hover {
            background-color: darken($white, 9);
        }
    }
}

// Breadcrumb
%breadcrumb {
    margin-bottom: 0;
    font-size: $text-18;

    .breadcrumb-item+.breadcrumb-item {
        &::before {
            content: "\e92f";
            font-family: $icons-font-family;
            color: $primary;
            font-size: $text-16;
            padding-right: 10px;
            padding-left: 2px;
            line-height: 28px;
        }
    }

    .breadcrumb-item.active {
        color: $dark;
    }
}

%btn-block {
    .btn {
        margin-right: 12px;
    }

    .btn:last-child {
        margin-right: 0;
    }
}

// Pagination

%k-grid-pager {
    background-color: $gray-account;
    border: none;
    border-top: 1px solid $common-box-border;
    border-radius: 0 0 $border-radius-4 $border-radius-4;
    padding: 9px 20px;

    @include bp(lg-screen) {
        justify-content: center;
    }

    @include bp(md-screen) {
        padding: 6px 20px;
    }

    &:focus {
        box-shadow: none;
    }

    .k-pager-nav {
        &.k-link {
            background-color: transparent;
            border-color: transparent;
            font-size: $text-14;
            font-weight: $font-semibold;
            color: $primary;
            min-width: 30px;
            height: 30px;

            &:focus {
                box-shadow: $box-shadow-0;
                outline: none;
            }
        }

        &.k-state-selected {
            border-radius: $border-radius-2;
            background-color: $primary;
            color: $white;
        }

        .k-icon {
            color: $gray-pgn;
        }
    }
}

%common-pagination {
    background-color: $gray-account;
    border: 1px solid $gray-pgn-border;
    border-radius: 0 0 $border-radius-12 $border-radius-12;
    padding: $space-10 $space20;

    .page-link {
        border: none;
        background-color: transparent;
        font-weight: $font-medium;
        color: $primary;
        padding: 0;
        width: 29px;
        height: 29px;
        line-height: 29px;
        text-align: center;
        box-shadow: $box-shadow-0;

        i {
            color: $gray-pgn;
        }
    }

    .active {
        .page-link {
            border-radius: $border-radius-2;
            background-color: $primary;
        }
    }

    .total-pages {
        color: $gray-pgn;
        font-weight: $font-medium;
        margin: 0;
        text-align: right;
        font-size: $text-14;
    }
}

// Tables
%table {
    color: $secondary;
    @extend %scroll;

    &.common-table {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        &.table> :not(:first-child) {
            border-top: none;
        }

        .filter-icon {
            display: inline-block;
            color: $black;
            margin-left: 20px;
            font-size: $text-20;
            line-height: 20px;
            vertical-align: top;
        }

        thead {
            th {
                background-color: $primary-light;
                font-weight: $font-semibold;
                padding: 12px 15px;
                border-bottom: none;
                box-shadow: $box-shadow-0;
                white-space: nowrap;
            }

            // th:first-child {
            //     border-radius: 6px 0px 0px 0px;
            // }
            // th:last-child {
            //     border-radius: 0px 6px 0px 0px;
            // }
        }

        tbody {
            td {
                padding: 12px 15px;
                border-bottom-color: $gray-account;
                box-shadow: $box-shadow-0;
                font-size: 14px;
                font-weight: $font-regular;

                @include bp(lg-screen) {
                    br {
                        display: none;
                    }
                }
            }

            tr:last-child td {
                border-bottom: none;
            }
        }

        .k-alt {
            background-color: $grid-alt;
        }

    }

    &.color-table {
        thead {
            th {
                background-color: $tertiary;
                color: $white;
                font-weight: 500;
                border-width: 0 0 1px 1px;
                border-color: rgba(255, 255, 255, 0.2);
                border-style: solid;

                .k-checkbox[type=checkbox] {
                    border-color: $white;
                }

                .k-checkbox:checked[type=checkbox] {
                    background-color: $primary;
                    border-color: $primary;
                }
            }

            tr {
                &:first-child {
                    th:first-child {
                        border-left-width: 0;
                    }
                }
            }
        }

        tbody {
            td {
                border-width: 0 0 1px 1px;
                border-style: solid;
                border-color: rgba(151, 151, 151, 0.2);

                &:first-child {
                    border-left-width: 0;
                }
            }
        }
    }

    .table-icon-link {
        padding: 4px;
        font-size: $text-16;
        color: $light;
        vertical-align: middle;
    }
}

.table-fixed {
    width: 100%;
    max-height: 620px;
    overflow-y: auto;

    thead {
        position: sticky;
        top: 0;
        z-index: 9;
    }
}

%cursor-pointer {
    cursor: pointer;
}

%status-files {
    font-size: $text-20;
    @extend %cursor-pointer;

    &.sf-1 {
        color: $primary;
    }

    &.sf-2 {
        color: $count-status;
    }

    &.sf-3 {
        color: $success;
    }
}

%valid-msg {
    background-color: $success-light;
    color: $white;
    display: inline-block;
    text-align: center;
    padding: 6px 10px 5px;
    font-weight: $font-medium;
    min-width: 80px;
    border-radius: $border-radius-4;
    @extend %cursor-pointer;
}

%valid-msg-check {
    font-size: $text-24;
    color: $success-light;
    min-width: 80px;
    text-align: center;
    @extend %cursor-pointer;
}

%upload-icon {
    font-size: $text-20;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    @extend %cursor-pointer;
}

// ========= Kendo Design START =========

%kendo-modal {
    &.modal-sm {
        width: 400px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-md {
        width: 575px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-lg {
        width: 830px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-xlg {
        width: 930px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-720 {
        width: 720px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-790 {
        width: 790px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-500 {
        width: 500px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-960 {
        width: 960px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.modal-1180 {
        width: 1180px;
        max-width: 96%;
        margin: 0 auto;
        right: 0;

        .k-dialog {
            width: 100%;
        }
    }

    &.select-facility-fixed {
        .k-dialog {
            // min-height: 722px;
            min-height: 450px;
        }

    }

    .k-dialog {
        border-radius: $border-radius-12;
        border: none;
        overflow: hidden;
    }

    .k-window-titlebar {
        padding: 8px 20px;
        background-color: $primary;
        color: $white;

        .k-window-action {
            opacity: 1;
            color: $white;
        }

        .k-icon {
            &.k-i-x {
                &::before {
                    font-family: $icons-font-family;
                    font-size: $text-20;
                    content: "\e92a";
                }
            }
        }
    }

    .k-window-content {
        max-height: 75vh;
        overflow: auto;
        padding: 20px 20px 0 20px;
        @extend %scroll;
    }

    &.window-content-p-0 {
        .k-window-content {
            padding: 0;
        }
    }

    &.modal-remove-button {
        .k-window-content {
            padding: 20px;
        }
    }

    // .common-popup-space {
    //     padding: 5px 20px;
    // }
    .k-window-title {
        font-size: $text-16;
        font-weight: 500;
        padding: 13px 0;
    }

    .k-dialog-buttongroup {
        border-top: none;
        padding: 20px 20px 20px 20px;
        display: block;

        @include bp(md-screen) {
            display: flex !important;
            gap: 10px;
        }

        .btn,
        .k-button {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            @include bp(md-screen) {
                margin-left: 0px;
            }
        }
    }

    .btn-left-right {
        display: flex;
        justify-content: space-between;

        .btn-left,
        .btn-right {
            flex: 0 0 auto;
            display: flex;

            @include bp(md-screen) {
                width: 100%;
                gap: 10px;
            }

            .btn {
                @include bp(md-screen) {
                    flex: 1 0 0%;
                    font-size: size(14);
                }
            }
        }
    }

    .information-content-box {
        .information-item {
            margin-bottom: size(16);

            &:last-child {
                margin-bottom: 0;
            }

            h6 {
                margin-bottom: size(2);
            }

            p {
                color: $gray;
                margin-bottom: 0;
            }
        }
    }

    &.k-dialog-wrapper {
        z-index: 99999;
    }
}

%k-grid {
    border: none;
    font-family: $font-family-base;
    color: $secondary;

    .k-grid-content {
        overflow-y: auto;
        z-index: 1;
        position: relative;
    }

    .k-grid-aria-root {
        overflow-y: auto;
    }

    // @include bp(md-screen) {
    //     .k-grid-content {
    //         table {
    //             min-width: 1020px;
    //         }
    //     }
    //     .k-grid-header-wrap {
    //         table {
    //             min-width: 1020px;
    //         }
    //     }
    // }

    td {
        border-width: 0 0 1px 0px;
        border-color: $gray-account;
        padding: 12px 15px;
        font-size: 14px;
        line-height: 20px;
        // white-space: nowrap;
        overflow: visible;
        // text-overflow: inherit;
        word-break: break-word;

        @include bp(md-screen) {
            padding: 10px 15px;
        }

        &.k-state-selected {
            background-color: $primary-light;
        }

        &.k-state-focused {
            box-shadow: none;
        }
    }

    .k-grid-header {
        background-color: $primary-light;
        border-right-color: transparent;
        padding-right: 0 !important;

        .k-grid-filter:focus {
            box-shadow: none;
        }

        .k-header {
            vertical-align: middle;
            white-space: pre-wrap;

            &.title-word-break {
                padding: 15px 25px !important;
                text-align: center;
            }
        }
    }

    th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        border: none;
        font-weight: 500;
        font-size: 14px;

        // color: $secondary;
        // white-space: nowrap;
        // overflow: visible;
        // text-overflow: inherit;
        @include bp(md-screen) {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;
        }

        &.k-state-selected {
            background-color: $primary-light;
        }

        &.k-state-focused {
            box-shadow: none;
        }

        .k-column-title {
            font-weight: $font-medium;
            font-size: $text-14;
            white-space: pre-wrap;
            // text-overflow: inherit;
            // white-space: nowrap;
        }

        .k-i-filter::before {
            font-family: $icons-font-family;
            content: "\e933";
            font-size: $text-20;
        }
    }

    .k-grid-add-row,
    .k-grid-edit-row {
        td {
            padding: 12px 15px;
        }
    }

    .k-command-cell {
        padding: 12px 15px;
    }

    .k-alt {
        background-color: $white;
    }

    tbody {
        tr {
            &:hover {
                background-color: $primary-light  !important;
            }

            &.k-state-selected {
                td {
                    background-color: $primary-light;
                }
            }
        }
    }

    tr {
        .k-state-hover {
            background-color: $white;
        }
    }

    .k-grid-norecords {
        font-size: 14px;
        font-weight: 600;
        color: $secondary;

        &:hover {
            background-color: transparent !important;
        }
    }

    .k-pager-sizes {
        .k-dropdownlist {
            width: 65px;
        }

        .k-picker .k-input-inner {
            height: 30px;
            padding: 3px 0px 3px 10px;
            text-align: center;
        }

        .k-button {
            padding: 3px 10px 3px 3px;

            .k-i-arrow-s::before {
                font-size: 18px;
            }
        }

        .k-label {
            color: $gray-pgn;
            font-weight: 500;
        }
    }

    .grid-action-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            a {
                background-color: transparent;
                border: none;
                border-radius: 0;
                font-size: 22px;
                padding: 4px;
                color: #929DA9;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: $primary;
                }
            }
        }
    }

    .grid-label-btn {
        .status-label-btn {
            border-radius: $border-radius-4;
            font-size: size(10);
            letter-spacing: 0.18px;
            line-height: size(16);
            text-align: center;
            color: $white;
            font-weight: $font-medium;
            padding: size(3) size(10);
            min-width: 100px;
            display: inline-block;
            cursor: pointer;

            &.font-md {
                font-size: size(14);
                line-height: size(18);
                padding: size(4) size(6);
                min-width: 55px;
            }

            &.bg-btn-gray {
                background-color: $light;

                &:hover {
                    background-color: darken($light, 9);
                }

                &[disabled],
                &:disabled {
                    opacity: 0.5;
                }
            }

            &.bg-btn-warning {
                background-color: $warning;

                &:hover {
                    background-color: darken($warning, 9);
                }
            }

            &.bg-btn-danger {
                background-color: $danger;

                &:hover {
                    background-color: darken($danger, 9);
                }
            }

            &.bg-btn-success {
                background-color: $success;

                &:hover {
                    background-color: darken($success, 9);
                }
            }

            &.bg-btn-info {
                background-color: $info;

                &:hover {
                    background-color: darken($info, 9);
                }
            }

            &.outline-btn-gray {
                background-color: $white;
                border: 1px solid $gray;
                color: $gray;

                &:hover {
                    background-color: darken($white, 9);
                }
            }

            &.yellow-bg-status {
                background-color: #fff39a;
                color: $secondary;
            }

            &.issue-outline-status {
                color: $danger;
                background-color: $white;
                border: 1px solid $danger;
            }

            &.white-gray-btn {
                background-color: $white;
                color: $gray;
                border: 1px solid $gray;
            }

            &.gray-white-btn {
                background-color: $gray;
                color: $white;
                border: 1px solid $gray;
            }

            &.yellow-black-btn {
                background-color: $yellow;
                color: $black;
                border: 1px solid $yellow;
            }

            &.white-red-btn {
                background-color: $white;
                color: $danger;
                border: 1px solid $danger;
            }

            &.red-white-btn {
                background-color: $red;
                color: $white;
                border: 1px solid $red;
            }

            &.blue-white-btn {
                background-color: $info;
                color: $white;
                border: 1px solid $info;
            }

            &.green-white-btn {
                background-color: $success;
                color: $white;
                border: 1px solid $success;
            }
            &.bg-btn-darkgreen{
                background-color: $darkgreen;
                border: 1px solid $darkgreen;
                color: $white;
            }
            &.outline-btn-white {
                background-color: $white;
                border: 1px solid $gray;
                color: $black;

                &:hover {
                    background-color: darken($white, 9);
                }
            }
        }

        &.multi-status {
            text-align: center;
            margin: 0 auto;

            .status-label-btn {
                margin-bottom: 6px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .label-with-tooltip {
        display: flex;
        align-items: center;

        .grid-tooltip,
        .label-icon {
            margin-left: 8px;

            &.mrg-right {
                margin-left: 0;
                margin-right: 8px;
            }
        }
        .grid-plus-btn{
            background-color: $primary;
            color: $white;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
            .custom-icons{
                color: $white;
                font-size: 14px;
            }
        }
        .custom-icons {
            font-size: 16px;
            color: #929DA9;
        }

        .icon-doller-window {
            color: $danger;
            font-size: 20px;
        }

        .icon-refresh {
            color: $danger;
        }
    }

    .icon-with-tooltip {
        display: flex;
        align-items: center;
        justify-content: center;

        .custom-icons {
            font-size: 16px;
            color: #929DA9;
        }

        .icon-paper-clip {
            color: $primary;
            font-size: 18px;
        }
    }

    .color-icon-bg {
        width: 28px;
        height: 28px;
        border-radius: $border-radius-4;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .custom-icons {
            font-size: 20px;
        }

        &.bg-ic-success {
            background-color: rgba($color: $success, $alpha: 0.3);
            color: $success;
        }

        &.bg-ic-danger {
            background-color: rgba($color: $danger, $alpha: 0.3);
            color: $danger;
        }

        &.bg-ic-warning {
            background-color: rgba($color: $warning, $alpha: 0.3);
            color: $warning;
        }

        &.bg-ic-gray {
            background-color: rgba($color: $light, $alpha: 0.3);
            color: $light;
        }

        &.bg-ic-info {
            background-color: rgba($color: $info, $alpha: 0.3);
            color: $info;
        }
    }

    .k-grid-header-none .k-grid-header {
        display: none;
    }

    .k-grid-header-locked {
        border-right: 4px solid rgba(255, 255, 255, 0.2);
    }

    .k-grid-content-locked td {
        border-color: rgba(151, 151, 151, 0.2);
    }

    .k-grid-header-locked .k-header {
        border-color: rgba(255, 255, 255, 0.2);
    }

    .k-grid-content-locked {
        border-right: 4px solid rgba(151, 151, 151, 0.2);
    }

    .k-grid-content-locked .k-state-selected td {
        background-color: $primary-light  !important;
    }

    .k-grid-content-locked .k-state-selected:hover td,
    .k-grid-content-locked .k-state-selected.k-state-hover td {
        background-color: $primary-light;
    }

    .files-links-box {
        .file-link {
            color: $primary;
            text-decoration: underline;

            &:hover {
                color: darken($primary, 9);
            }
        }
    }

    .col-success {
        color: $success;
        font-weight: $font-medium;
    }

    .field-with-icon {
        display: flex;
        align-items: center;

        .icon-info {
            color: $danger;
            font-size: 18px;
            margin-right: 6px;
            transform: rotate(180deg);
        }
    }

    .k-master-row {
        td:focus {
            box-shadow: none;
        }

        .k-hierarchy-cell {
            .k-icon {
                color: $white;
                font-size: 16px;
                background-color: $tertiary;
                border-radius: $border-radius-4;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px;
                margin: 0 auto;

                &:hover {
                    background-color: darken($tertiary, 9);
                }

                &.k-i-plus::before {
                    content: "\e92f";
                    font-family: "icomoon" !important;
                }

                &.k-i-minus::before {
                    content: "\e938";
                    font-family: "icomoon" !important;
                }
            }
        }
    }

    .k-detail-row {
        background-color: $white;

        &:hover {
            background-color: $white  !important;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .k-detail-cell {
        background-color: #FBFCFC;
        border-radius: $border-radius-6;
    }

    .k-hierarchy-col {
        width: 50px;
    }
}

.k-tooltip-wrapper {
    .k-popup {
        box-shadow: none !important;
        border: none;
        border-radius: $border-radius-6;
        background-color: transparent;
        padding: 6px;
    }

    .k-tooltip {
        background-color: $white;
        border: 1px solid $gray-pgn-border;
        border-radius: $border-radius-6;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
        color: $secondary;
        font-size: 13px;
        line-height: 18px;
        padding: 10px;
    }

    .k-callout {
        color: $white;
    }
}

.notification {
    font-size: 15px;
    font-weight: 300;

}

.grid-round-status {
    .status-round {
        border: 1px solid $gray;
        border-radius: $rounded-pill;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    .status-success {
        background-color: $success;
        border-color: $success;
    }

    .status-warning {
        background-color: $warning;
        border-color: $warning;
    }

    .status-danger {
        background-color: $danger;
        border-color: $danger;
    }

    .custom-icons {
        color: $white;
        font-size: size(16);

        &.icon-check {
            font-size: size(12);
        }

    }
}

/* fixed header in print css */
.page-template {
    font-family: "DejaVu Sans", "Arial", sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.page-template .header {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    border-bottom: 1px solid #888;
    color: #888;
}

.page-template .footer {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    border-top: 1px solid #888;
    text-align: center;
    color: #888;
}

.header {
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 20px 0;
    //border-bottom: 1px solid #e5e5e5;
    color: #3aabf0;
}

.address {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    .for {
      h2 {
        font-size: 36px;
        margin-bottom: size(16);
      }
      h3 {
        font-size: 20px;
      }
    }
  }

.from p,
.for p {
    color: #787878;
}
.k-pdf-export .k-filterable .k-grid-filter{
    display:none;
  }

 // new pdf css

 .page-template-pdf {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .footer {
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;
        border-top: 1px solid #888;
        text-align: center;
        color: #888;
    }

 .header_pdf{
    margin: 0 auto;
    width: 97%;
    margin-top: 28px;
    table {
        width: 100%;
      }
      
      td, th {
        // border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        h2 {
            font-size: 36px;
           margin-bottom: size(8);
          }
          h3 {
            font-size: 20px;
          }
      }
      .for p {
        color: #787878;
    }
 }
}
 //new pdf css
//chart integration css
.col-odd-custom {
    flex: 0 0 auto;
    width: 20%;
    margin-bottom: 24px;
    flex-grow: 1;

    @include bp(xl-screen) {
        width: 33.33%;
    }

    @include bp(md-screen) {
        width: 50%;
    }
}

.ex-col-odd-custom {
    flex: 0 0 auto;
    width: 16%;
    margin-bottom: 24px;
    flex-grow: 1;

    @include bp(xl-screen) {
        width: 33.33%;
    }

    @include bp(md-screen) {
        width: 50%;
    }
}

.vob-info-box {
    text-align: center;
    border-radius: $border-radius-10;
    padding: 20px 15px;
    background-color: $info;
    color: $white;
    height: 100%;
    cursor: pointer;

    @include bp(md-screen) {
        padding: 15px;
    }

    h6 {
        font-size: 14px;
        line-height: 22px;
        color: $white;
        margin-bottom: 6px;
    }

    h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        color: $white;
        word-break: break-all;

        @include bp(md-screen) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    span {
        position: relative;
        color: $white;
        display: block;
        text-align: right;
        margin-top: 5px;
    }
    &.ex-vob-info-box{
        h2 {
            font-size: 25px;
            line-height: 34px;
            @include bp(md-screen) {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &.tpn-card-active{
        background-color: rgb(217, 237, 246);
        color: rgb(98, 203, 230);
        h6{
            color: rgb(98, 203, 230);
        }
        h2{
            color: rgb(98, 203, 230);
        }
    }
}

.mix-box {
    padding: 20px;
    text-align: center;
    max-height: 400px;
    overflow-y: auto;

    ul {
        max-width: 65%;
        margin: 0 auto;

        li {
            background-color: $tertiary;
            padding: 10px;
            color: $white;
            border-radius: $border-radius-4;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &.item-selected {
                background-color: rgb(217, 237, 246);
                color: rgb(98, 203, 230);
            }
        }
    }
}

//chart integration css

.textarea-with-btn {
    position: relative;

    textarea {
        &.form-control {
            height: 135px;
        }
    }

    .textarea-action-btn {
        position: absolute;
        right: 15px;
        bottom: 6px;
    }
}

.file-with-download-btn {
    display: flex;

    .form-group-inner {
        width: 100%;
    }

    .download-btn {
        a {
            width: 50px;
            height: 50px;
            background-color: $primary;
            color: $white;
            font-size: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            margin-left: 16px;
            margin-top: 28px;

            &:hover {
                background-color: #0491b7;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.chart-not-found-msg {
    height: calc(100% - 51px);
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 15px;
}

.multiselect-box {
    .k-input-inner {
        padding: 6px 3px;
        font-size: 14px;
    }
}

.k-list-ul {
    .k-checkbox[type=checkbox] {
        margin-right: 8px;
    }
}

textarea.form-control.add-patient-address-textarea {
    height: 139px;
}

.k-grouping-row,
.k-group-cell {
    background-color: transparent;
    color: $secondary;
}

.k-grid .k-grouping-row+tr td {
    border-top-width: 0px;
}

.k-group-footer td,
.k-group-footer .k-grid-footer-sticky {
    background-color: transparent;
}

.k-grid .k-group-footer .k-group-cell+td {
    border-left-width: 0px;
}

.k-grid .k-group-footer td {
    border-top-width: 0px;
}

.k-grid .k-grouping-row p {
    font-size: 16px;
    font-weight: 500;
    color: $secondary;
}

.sum-grid-bg-color {
    .total-sum-text {
        font-size: 16px;
        font-weight: 600;
        color: $secondary;
        padding: 12px 15px;
        background-color: $primary-light;
        display: block;
        text-align: right;
    }

    .k-group-footer {
        td {
            padding: 0;
            border: none;
        }
    }
}

.k-footer-template {
    background-color: $tertiary;

    td {
        color: $white;
        border-width: 0 !important;
    }

    .total-sum-footer {
        font-size: 16px;
        font-weight: 600;
        display: block;
        text-align: right;
    }

    .total-sum-title {
        font-size: 16px;
        font-weight: 600;
        display: block;
    }
}

.grid-border {
    .k-grid td {
        border-width: 0 1px 1px 0px;
    }

    .k-grid th {
        border-right: 1px solid $common-box-border;
    }

    .k-group-cell {
        border-right: 0 !important;
    }
    .k-grid .k-alt {
        background-color: rgba(0, 0, 0, 0.02);
    }
}
.multiselect-dropdown-height{
    height: 60px;
    overflow: auto;
}
.numeric-increase-remove{
    .k-input-spinner {
        display: none;
    }
}
.box-comman-height-auto{
    height: auto;
    overflow: auto;
}

.exDashboard-card-modal{
    
    .k-virtual-content{
        max-height: 62vh;
    }
}
.align-right{
    .k-cell-inner{
        > .k-link{
            justify-content:end;
        }
    }   
}
.padd-right-25{
    padding-right: 25px;
}
.padd-left-25{
    padding-left: 25px;
    .k-grid &{
        padding-left: 25px;
    }
}
.selected-facility-name li {
    font-size: 14px;
    list-style: inside;
    color: #122B45;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
}
.h-title{
    color: $dark;
}
.reports-text-align-right{
    text-align: right;
    .k-grid &{
        text-align: right;
    }
}
.custom-k-grid-x-auto{
    overflow-x: auto;
}
.custom-pad-40{
    padding-right: 40px !important;
}
.padd-right-35{
    padding-right: 35px;
}

.auto-width{
    flex: 0 0 auto;
    width: 20%;
    flex-grow: 1;
}
.umcontainer{
    .headerLogo{
        height: size(65);
        background-color: #122B45;
        display: flex;
        align-items: center;
    }
    .MainBanner{
        margin: size(20) size(60);
        .bannerText{
            .bannerMainText{
                padding-bottom: size(15);
                padding-top: size(5);
            }
        }
        .user-agreement-inner {
            padding: size(15);
            background-color: #FFFFFF;
            border-radius: size(10);
            box-shadow: 0 2px 10px 0 #929DA9;
            height: calc(100vh - 310px);
            overflow: scroll;
            .container{
                display: table-row;
            }
         }
        .footerContent{
            display: flex;
            justify-content: space-between;
            margin-top: size(20);
            @include bp(lg-screen) {
                display: block;
            }
            .inputTextButtons{
                .form-group{
                    display: flex;
                    align-items: center;
                    margin: size(0);
                    input{
                        width: size(450);
                        max-width: 100%;
                        margin-right: size(16);
                        @include bp(md-screen) {
                            width: 100%;
                        }
                        @include bp(sm-screen) {
                            width: 100%;
                        }
                    }
                
                }
                .AgrrementText{
                    font-size: size(10);
                    line-height: size(13);
                    color: #827c7c;
                    padding-top: size(5);
                }
                
            }
            .agrrementDate{
                @include bp(lg-screen) {
                    margin-top: size(20);
                }
            }
        }
    }

}
.executive-report-box{
    border: 1px solid $primary;
    border-radius: $border-radius-10;
    padding: size(60) size(16);
    text-align: center;
    background-color: $primary;
    cursor: pointer;
    margin-bottom: size(24);
    @include transition(all 0.3s ease-in-out);
    h4{
        font-size: size(18);
        color: $white;
    }
    &:hover{
        background-color: rgb(217, 237, 246);
        border-color: rgb(217, 237, 246);
        h4{
            color: $primary;
        }
    }
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.priority-status-item {
    background-color: goldenrod !important;
}

.priority-status-text {
    color: goldenrod;
    font-weight: bold;
}

.time-lapsed-text {
    color: #FFCACA;
}

.play-icon {
    content: url(../../icons/play-icon-white.svg);
}

.pause-icon {
    content: url(../../icons/pause-icon-white.svg);
}

.call-icon-white {
    margin-right: 6px;
    content: url(../../icons/phone-call-svgrepo-com-white.svg);
}
.call-icon-black {
    margin-right: 6px;
    content: url(../../icons/phone-call-svgrepo-com-black.svg);
}

.email-gray-icon {
    content: url(../../icons/email-gray.svg);
}

.email-white-icon {
    content: url(../../icons/email-white.svg);
}